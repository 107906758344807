import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Sidebar from "../sidebar/index.js";
import axios from "axios";
import QRCode from "qrcode.react";
import ConfirmPopup from "../confirmpopup/index.js";
import {ContentWrapper,HeaderBar,SidebarContainer,ViewContainer,CategorySection,CategoryTitle,
    ContentSection,SectionTitle,InputContainer,InputLabel,InputButton, PreviewWrapper,
    PreviewContainer, TabWrapper, TabSelectorContainer, TabContentView, TabName, InputToggle, 
    FrameContainer,BackButton,ModelTitle,LargeButtonSection,LargeButtonElement,LargeButtonWrapper,
    LargeIconButton,PreviewButtonContainer,PreviewButtonElement,PreviewButtonIcon,CentralConfigSection,
    CloseButton,ConfigToggleElementRight,FooterButtonConfigCOntainer,HeaderConfigSection,HeaderConfigTitleRow,
    HeaderConfigToggle,PublishConfigButton,SaveConfigButton,FooterConfigSection,ConfigToggleElementLeft,
    InputDescription,InputLeftSection,BackButtonIcon,TextareaInput,TextareaInputButtonContainer,
    TextareaInputLabel,TextareaSingleInputButton,TextAreaInputContainer, TextAreaSelectedBox,
    TextareaSingleEmptyButton,HorizontalTabContainer, HorizontalLeftArrow,HorizontalRightArrow,
    QRButtonElement, QRCodeContainer,QRCloseButton, QRCodeWrapper,QRIcon,QRHeader,PremiumTag,
    QRButtonHalfContainer,QRButtonHalfLeft,QRButtonHalfRight,CodeButtonHalfLeft,CodeButtonHalfRight,
    EmbedButtonElement,CodeIcon,ConfigurationEasyToggle, PremiumButtonElement,QRButtonHeader, 
    QRdirectButton, ModelDetails, ModelDetailRow} from './configurationpageElements';
import ConfigurationButton from "../configurationbutton/index.js";
import useAuth from "../../hooks/useAuth.js";
import ConfigurationPreview from "../configurationpreview/index.js";

import backicon from '../../img/back24icon.png';
import deleteicon from '../../img/delete32icon.png';
import analyticsicon from '../../img/analytics32icon.png';
import renameicon from '../../img/rename32icon.png';
import settingsicon from '../../img/settings32icon.png';
import previewicon from '../../img/preview24icon.png';
import closeicon from '../../img/close24icon.png';
import Dialog from "../dialog/index.js";

import ARicon from "../../img/analyticsar36icon.png";
import VRicon from "../../img/analyticsvr36icon.png";
import codeicon from "../../img/code24icon.png";

const ConfigurationPage = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { auth } = useAuth();
    //const { conf, type, modelInfo } = location.state || {};
    const { confprod, conftest, modelInfo, configurationAlreadyOpen } = location.state || {};
    const [isConfOpen, setIsConfOpen] = useState(configurationAlreadyOpen);
    const [activeTab, setActiveTab] = useState(0);
    const [prodState, setProdState] = useState({prod: confprod});
    const [testState, setTestState] = useState({test: conftest});
    const [isLoading,setIsLoading] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0); // State to trigger the refresh
    const [showQRARCode, setShowQRARCode] = useState(false);
    const [showQRVRCode, setShowQRVRCode] = useState(false);
    const [showTestCode, setShowTestCode] = useState(false);
    const [showProdCode, setShowProdCode] = useState(false);
    const [color] = useState('000000');
    const [isNameEditMode, setIsNameEditMode] = useState(false);
    const [isNameEditPopup, setIsNameEditPopup] = useState(false);
    const [isDeletePopup, setIsDeletePopup] = useState(false);
    const [modelName, setModelName] = useState(modelInfo.sfDoc.name);
    const [newModelName, setNewModelName] = useState(modelName);
    const [isLoadingActive, setIsLoadingActive] = useState(false);
    const [autoSpin, setAutoSpin] = useState(0);
    const [antiAliasingType, setAntiAliasingType] = useState('');
    const [mode, setMode] = useState('prod'); // Mode can be 'test' or 'prod'  
    const [copiedtest, setCopiedtest] = useState(false);
    const [copiedlive, setCopiedlive] = useState(false);
    const [publishButtonText, setPublishButtonText] = useState('Pubblica');

    const [easyConfigProd, setEasyConfigProd] = useState(true)
    const [easyConfigTest, setEasyConfigTest] = useState(true)

    const qrARCodeUrl = "https://portlab.3danimate.it/redirect/?id_utente="+ auth.id +"&id_modello="+ modelInfo._id +"&other_code="+ modelInfo.uid +"&ARorVR=AR";
    const qrVRCodeUrl = "https://portlab.3danimate.it/redirect/?id_utente="+ auth.id +"&id_modello="+ modelInfo._id +"&other_code="+ modelInfo.uid +"&ARorVR=VR";
    const APIURL = `${process.env.REACT_APP_API_BASE_URL}`;
    const tabs = [
        { title: "Caricamento"},{ title: "Interfaccia"},{ title: "Animazioni"},
        { title: "Camera"},{ title: "Ottimizzazione"},{ title: "Grafica"},{ title: "New HUD"},
      ];
    /*  
    const tabs = [
          { title: "Caricamento"},{ title: "Interfaccia"},{ title: "Animazioni"},{ title: "Textarea"},
          { title: "Camera"},{ title: "Ottimizzazione"},{ title: "Grafica"},
    ];
    */

    const [visibleTextareaId, setVisibleTextareaId] = useState(1);
    const [prodtextareaValues, setProdtextareaValues] = useState({
        1: confprod.textarea.textarea1,
        2: confprod.textarea.textarea2,
        3: confprod.textarea.textarea3,
        4: confprod.textarea.textarea4,
        5: confprod.textarea.textarea5,
        6: confprod.textarea.textarea6,
        7: confprod.textarea.textarea7,
        8: confprod.textarea.textarea8,
    });

    const [testtextareaValues, setTesttextareaValues] = useState({
        1: conftest.textarea.textarea1,
        2: conftest.textarea.textarea2,
        3: conftest.textarea.textarea3,
        4: conftest.textarea.textarea4,
        5: conftest.textarea.textarea5,
        6: conftest.textarea.textarea6,
        7: conftest.textarea.textarea7,
        8: conftest.textarea.textarea8,
    });

    const [prodSettings, setProdSettings] = useState({
        name: confprod.name,
        isloadingactive: confprod.isloadingactive,
        userlogo: confprod.userlogo,
        loadingtext: confprod.loadingtext,
        autostart: confprod.autostart,
        animationautoplay: confprod.animationautoplay,
        autospin: confprod.autospin,
        spinspeed: confprod.spinspeed,
        cameraintrozoom: confprod.cameraintrozoom,
        isfovactive: confprod.isfovactive,
        fov: confprod.fov,
        orbitcostrictionpan: confprod.orbitcostrictionpan,
        ispancameralimitactive: confprod.ispancameralimitactive,
        pancamerauplimit: confprod.pancamerauplimit,
        pancameradownlimit: confprod.pancameradownlimit,
        iscamerayawlimitactive: confprod.iscamerayawlimitactive,
        yawcameraleftlimit: confprod.yawcameraleftlimit,
        yawcamerarightlimit: confprod.yawcamerarightlimit,
        iscamerazoomlimitactive: confprod.iscamerazoomlimitactive,
        camerazoomcloselimit: confprod.camerazoomcloselimit,
        camerazoomfarlimit: confprod.camerazoomfarlimit,
        scrollwheel: confprod.scrollwheel,
        uistop: confprod.uistop,
        uitheme: confprod.uitheme,
        uianimations: confprod.uianimations,
        uiannotations: confprod.uiannotations,
        uiannotationsmenu: confprod.uiannotationsmenu,
        uicontrols: confprod.uicontrols,
        uifadeout: confprod.uifadeout,
        uifullscreen: confprod.uifullscreen,
        uigeneralcontrols: confprod.uigeneralcontrols,
        uihelp: confprod.uihelp,
        uihint: confprod.uihint,
        uiinfo: confprod.uiinfo,
        uisound: confprod.uisound,
        uiloading: confprod.uiloading,
        uistart: confprod.uistart,
        uivr: confprod.uivr,
        uiar: confprod.uiar,
        uiarqrcode: confprod.uiarqrcode,
        backgroundtransparent: confprod.backgroundtransparent,
        backgroundcolor: confprod.backgroundcolor,
        isoriginalbackground: confprod.isoriginalbackground,
        doubleclick: confprod.doubleclick,
        dof: confprod.dof,
        preventlightrotation: confprod.preventlightrotation,
        texturequality: confprod.texturequality,
        checkmobile: confprod.checkmobile,
        textarea: {
            fill: confprod.textarea.fill,
            textarea1: confprod.textarea.textarea1,
            textarea2: confprod.textarea.textarea2,
            textarea3: confprod.textarea.textarea3,
            textarea4: confprod.textarea.textarea4,
            textarea5: confprod.textarea.textarea5,
            textarea6: confprod.textarea.textarea6,
            textarea7: confprod.textarea.textarea7,
            textarea8: confprod.textarea.textarea8
        },
        //annotations: confprod.annotations,
        isantialiasingactive: confprod.isantialiasingactive,
        antialiasing: confprod.antialiasing,
        ispostprocessactive: confprod.ispostprocessactive,
        postprocess: confprod.postprocess,
        isshadingstyleactive: confprod.isshadingstyleactive,
        shadingstylename: confprod.shadingstylename,
        shadingstyleval: confprod.shadingstyleval,
        preload: confprod.preload,
        mergematerials: confprod.mergematerials,
        graphoptimizer: confprod.graphoptimizer,
        materialpacking: confprod.materialpacking,
        iswidthconstraintactive: confprod.iswidthconstraintactive,
        widthconstraint: confprod.widthconstraint,
        isheightconstraintactive: confprod.isheightconstraintactive,
        heightconstraint: confprod.heightconstraint,
        arqrlink: qrARCodeUrl,
        vrqrlink: qrVRCodeUrl,
        idmodello: modelInfo._id,
        usehud: confprod.usehud,
        showhudannotations: confprod.showhudannotations,
        showannotationsnumbers: confprod.showannotationsnumbers,
        showhudanimations: confprod.showhudanimations,
        showhudpositions: confprod.showhudpositions,
        hudpositiondistance: confprod.hudpositiondistance,
        annotationdotcolor: confprod.annotationdotcolor,
        annotationtextcolor: confprod.annotationtextcolor
    });

    const [testSettings, setTestSettings] = useState({
        name: conftest.name,
        isloadingactive: conftest.isloadingactive,
        userlogo: conftest.userlogo,
        loadingtext: conftest.loadingtext,
        autostart: conftest.autostart,
        animationautoplay: conftest.animationautoplay,
        autospin: conftest.autospin,
        spinspeed: conftest.spinspeed,
        cameraintrozoom: conftest.cameraintrozoom,
        isfovactive: conftest.isfovactive,
        fov: conftest.fov,
        orbitcostrictionpan: conftest.orbitcostrictionpan,
        ispancameralimitactive: conftest.ispancameralimitactive,
        pancamerauplimit: conftest.pancamerauplimit,
        pancameradownlimit: conftest.pancameradownlimit,
        iscamerayawlimitactive: conftest.iscamerayawlimitactive,
        yawcameraleftlimit: conftest.yawcameraleftlimit,
        yawcamerarightlimit: conftest.yawcamerarightlimit,
        iscamerazoomlimitactive: conftest.iscamerazoomlimitactive,
        camerazoomcloselimit: conftest.camerazoomcloselimit,
        camerazoomfarlimit: conftest.camerazoomfarlimit,
        scrollwheel: conftest.scrollwheel,
        uistop: conftest.uistop,
        uitheme: conftest.uitheme,
        uianimations: conftest.uianimations,
        uiannotations: conftest.uiannotations,
        uiannotationsmenu: conftest.uiannotationsmenu,
        uicontrols: conftest.uicontrols,
        uifadeout: conftest.uifadeout,
        uifullscreen: conftest.uifullscreen,
        uigeneralcontrols: conftest.uigeneralcontrols,
        uihelp: conftest.uihelp,
        uihint: conftest.uihint,
        uiinfo: conftest.uiinfo,
        uisound: conftest.uisound,
        uiloading: conftest.uiloading,
        uistart: conftest.uistart,
        uivr: conftest.uivr,
        uiar: conftest.uiar,
        uiarqrcode: conftest.uiarqrcode,
        backgroundtransparent: conftest.backgroundtransparent,
        backgroundcolor: conftest.backgroundcolor,
        isoriginalbackground: conftest.isoriginalbackground,
        doubleclick: conftest.doubleclick,
        dof: conftest.dof,
        preventlightrotation: conftest.preventlightrotation,
        texturequality: conftest.texturequality,
        checkmobile: conftest.checkmobile,
        textarea: {
            fill: conftest.textarea.fill,
            textarea1: conftest.textarea.textarea1,
            textarea2: conftest.textarea.textarea2,
            textarea3: conftest.textarea.textarea3,
            textarea4: conftest.textarea.textarea4,
            textarea5: conftest.textarea.textarea5,
            textarea6: conftest.textarea.textarea6,
            textarea7: conftest.textarea.textarea7,
            textarea8: conftest.textarea.textarea8
        },
        //annotations: conftest.annotations,
        isantialiasingactive: conftest.isantialiasingactive,
        antialiasing: conftest.antialiasing,
        ispostprocessactive: conftest.ispostprocessactive,
        postprocess: conftest.postprocess,
        isshadingstyleactive: conftest.isshadingstyleactive,
        shadingstylename: conftest.shadingstylename,
        shadingstyleval: conftest.shadingstyleval,
        preload: conftest.preload,
        mergematerials: conftest.mergematerials,
        graphoptimizer: conftest.graphoptimizer,
        materialpacking: conftest.materialpacking,
        iswidthconstraintactive: conftest.iswidthconstraintactive,
        widthconstraint: conftest.widthconstraint,
        isheightconstraintactive: conftest.isheightconstraintactive,
        heightconstraint: conftest.heightconstraint,
        arqrlink: qrARCodeUrl,
        vrqrlink: qrVRCodeUrl,
        idmodello: modelInfo._id,
        usehud: conftest.usehud,
        showhudannotations: conftest.showhudannotations,
        showannotationsnumbers: conftest.showannotationsnumbers,
        showhudanimations: conftest.showhudanimations,
        showhudpositions: conftest.showhudpositions,
        hudpositiondistance: conftest.hudpositiondistance,
        annotationdotcolor: conftest.annotationdotcolor,
        annotationtextcolor: conftest.annotationtextcolor
    });

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }
    

    {/*

    !! risoluzione per embed in VR !!

    /embed?&vr_quality=1.5

    1.5 medium (default)
    2.5 high
    3.5 very high

    /embed?&pratio=2.5&vr_scale_rtt=1&autostart=1&maxDevicePixelRatio=1.

    http://bit.ly/liopleu

    !! start AR per pulsante !!

    startAR( [callback] )

    Displays the AR popup if available. See App-free AR for Enterprise

    The callback has one parameter: an error or null if the operation succeeded.

    api.startAR(function(err) {
        if (!err) {
            window.console.log('Starting AR');
        }
    });

    */}
    
    const handleProdtextareaChange = (id, value) => {
        setProdtextareaValues((prevValues) => ({
        ...prevValues,
        [id]: value,
        }));
    };

    const handleTesttextareaChange = (id, value) => {
        setTesttextareaValues((prevValues) => ({
        ...prevValues,
        [id]: value,
        }));
    };
    
    const handleTestClearTextarea = (id) => {
        setTesttextareaValues((prevValues) => ({
        ...prevValues,
        [id]: "",
        }));
    };

    const handleButtonClick = (id) => {
        setVisibleTextareaId(id);
    };

    //const [config, setConfig] = useState(conf);

    console.log("configuration page modelinfo, location.state.modelInfo , location.state : ", modelInfo, location.state.modelInfo, location.state);
    //console.log(location.state.selectedModelHDImage)
    //console.log(location.state);

    const [state, setState] = useState({
        test: conftest,
        prod: confprod,
    });

    const [settings, setSettings] = useState({
        test: conftest,
        prod: confprod,
    });

    const tabRefs = useRef(tabs.map(() => React.createRef()));
    const containerRef = useRef(null);
    const scrollThreshold = 0.3; // Adjust this value to control the scroll speed


    const handleMouseDown = event => {
        event.preventDefault();
        containerRef.current.style.cursor = "grabbing";
        const startX = event.clientX;

        const handleMouseMove = event => {
            const deltaX = event.clientX - startX;
            containerRef.current.scrollLeft -= deltaX * scrollThreshold;
        };

        const handleMouseUp = () => {
            containerRef.current.style.cursor = "grab";
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

  const handleWheel = event => {
    event.stopPropagation(); // Add this line to prevent the event from bubbling up
    containerRef.current.scrollLeft += event.deltaY * scrollThreshold;  
  };

  const handleScrollLeft = () => {
    containerRef.current.scrollBy({
      left: -200, // Adjust the scroll distance as per your requirements
      behavior: 'smooth',
    });
  };

  const handleScrollRight = () => {
    containerRef.current.scrollBy({
      left: 200, // Adjust the scroll distance as per your requirements
      behavior: 'smooth',
    });
  };
    
    const handleRefreshClick = () => {
        // console.log("refreshing");
        setRefreshCount(prevCount => prevCount + 1); // Increment the refresh count to trigger the refresh
        console.log("refreshCount:", refreshCount);
    };


    const filterSettings = (settings) => {
        // Map of allowed field names to their corresponding setting key
        const allowedFields = {
            animation_autoplay: 'animationautoplay',
            annotation: 'uiannotations',
            annotation_cycle: 'annotation_cycle',
            annotation_tooltip_visible: 'annotation_tooltip_visible',
            annotations_visible: 'ui_annotations',
            autospin: 'autospin',
            autostart: 'autostart',
            camera: 'camera',
            dnt: 'dnt',
            dof_circle: 'dof',
            fps_speed: 'fps_speed',
            max_texture_size: 'max_texture_size',
            navigation: 'navigation',
            preload: 'preload',
            scrollwheel: 'scrollwheel',
            transparent: 'backgroundtransparent',
            double_click: 'double_click',
            graph_optimizer: 'graphoptimizer',
            material_packing: 'materialpacking',
            merge_materials: 'mergematerials',
            orbit_constraint_pan: 'orbit_constraint_pan',
            orbit_constraint_pitch_down: 'orbit_constraint_pitch_down',
            orbit_constraint_pitch_up: 'orbit_constraint_pitch_up',
            orbit_constraint_yaw_left: 'orbit_constraint_yaw_left',
            orbit_constraint_yaw_right: 'orbit_constraint_yaw_right',
            orbit_constraint_zoom_in: 'orbit_constraint_zoom_in',
            orbit_constraint_zoom_out: 'orbit_constraint_zoom_out',
            prevent_user_light_rotation: 'prevent_user_light_rotation',
            ui_animations: 'uianimations',
            ui_annotations: 'uiannotations',
            ui_annotationsmenu: 'uiannotationsmenu',
            ui_controls: 'uicontrols',
            ui_fadeout: 'uifadeout',
            ui_fullscreen: 'uifullscreen',
            ui_general_controls: 'uigeneralcontrols',
            ui_help: 'uihelp',
            ui_hint: 'uihint',
            ui_infos: 'uiinfo',
            ui_settings: 'ui_settings',
            ui_start: 'uistart',
            ui_vr: 'uivr',
            ui_ar: 'uiar',
            ui_ar_help: 'ui_ar_help',
            ui_ar_qrcode: 'uiarqrcode',
            ui_color: 'uicolor',
            ui_stop: 'uistop',
            ui_theme: 'ui_theme'
        };
      
        const filteredSettings = {};

        for (const [field, setting] of Object.entries(allowedFields)) {
            if (settings.hasOwnProperty(setting)) {
            filteredSettings[field] = settings[setting];
            }
        }
      
        return filteredSettings;
    };     

    const updateSettings = (newSettings) => {
        const filteredSettings = filterSettings(newSettings);
        setSettings(filteredSettings);
    };

    const handleValueChange = (id, newValue, type) => {
        console.log("handleValueChange id, newValue, type: ", id, newValue, type)
        setState((prevState) => ({
          ...prevState,
          [mode]: {
            ...prevState[mode],
            [id]: newValue,
          },
        }));
    };

    const handleProdValueChange = (id, newValue, type) => {
        console.log("handleProdValueChange id, newValue, type, prodSettings: ", id, newValue, type, prodSettings)
        setProdSettings((prevState) => ({
          ...prevState,
          [id]: newValue,
        }))
    };

    const handleTestValueChange = (id, newValue, type) => {
    console.log("handleTestValueChange id, newValue, type: ", id, newValue, type)
    setTestSettings((prevState) => ({
        ...prevState,
        [id]: newValue,
    }));
    console.log(testSettings)
    };

    const handleModeChange = newMode => {
        console.log("handleModeChange state[mode], prodState, mode: ", state[mode], prodState, mode);
        if (isDirty()) {
          const confirmation = window.confirm("Do you want to save the changes?");
          if (confirmation) {
            if (mode === "prod") {
              setProdState(state[mode]);
            } else if (mode === "test") {
              setTestState(state[mode]);
            }
          }
        }
      
        setMode(newMode);
      };
      
      // Helper function to check if any field is modified
      const isDirty = () => {
        const currentState = state[mode];
        const previousState = mode === "prod" ? prodState : testState;
        return JSON.stringify(currentState) !== JSON.stringify(previousState);
      };

    const handleConfirmation = confirmed => {
    if (confirmed) {
        if (mode === "prod") {
        setProdState(state[mode]);
        } else if (mode === "test") {
        setTestState(state[mode]);
        }
    }
    
    // Change the mode regardless of the confirmation result
    setMode(confirmed ? switchMode(mode) : mode);
    };
    
    // Helper function to switch mode between 'prod' and 'test'
    const switchMode = currentMode => {
    return currentMode === "prod" ? "test" : "prod";
    };

    
    useEffect(() => {
        console.log("prodSettings changed: ", prodSettings);
    }, [prodSettings])

    useEffect(() => {
        // Reset the configuration values based on the current mode
        console.log("useEffect mode, prodstate, teststate")
        if (mode === "prod") {
          setState({ ...state, prod: prodState });
        } else if (mode === "test") {
          setState({ ...state, test: testState });
        }
      }, [mode, prodState, testState]);

      const handleSave = () => {
        if (mode === "prod") {
          //setProdState(state[mode]);
          console.log("handleSave prodSettings: ",prodSettings)
          setProdState({ ...state,prod:prodSettings});
          handleRefreshClick(); // Call the function to refresh the iframe
        } else if (mode === "test") {
          //setTestState(state[mode]);
          setProdState({ ...state,test:prodSettings});
          handleRefreshClick(); // Call the function to refresh the iframe
        }
      };
      
      const handlePublish = (kind) => {
        setPublishButtonText('Loading...');
        // Make an API call to save the settings
        console.log("handlePublish prodSettings: ",prodSettings, kind)
        saveConfig(kind);
      };

    const handleTabClick = (index) => {
        setActiveTab(index);
        const selectedTabRef = tabRefs.current[index];
        if (selectedTabRef && selectedTabRef.current) {
          selectedTabRef.current.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }
      };

    const saveConfig =  async (configkind) => {
        console.log("saveConfig configkind: ",configkind)
        //console.log(state)
        if(configkind === 1){
            console.log("saveConfig prodSettings: ",prodSettings)

        }else{
            console.log("saveConfig testSettings: ",testSettings)

        }
        // 0 : test, 1 : prod.

        // TO DO : aggiornare anche preview.

        try {
            //const response = await axios.post( APIURL + '/forum/model/update/conf' , {
                const response = await axios.post( APIURL + '/forum/models/update/conf' , {
                uid: modelInfo.uid,
                kind: configkind,
                conf: configkind === 1 ? prodSettings : testSettings,
            } , {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id, }
            }
            )
            .then(response => {
                // handle success
                if(response.data.success){
                  console.log("/forum/model/update/conf response: ", response);
                  setPublishButtonText('Pubblicato');
                  setTimeout(() => setPublishButtonText('Pubblica'), 2000);
                } else {
                  console.log("/forum/model/update/conf response: ", response);
                  setPublishButtonText('ERRORE');
                  setTimeout(() => setPublishButtonText('Pubblica'), 2000);
                }
              })
        } catch (error) {
            console.error("/forum/model/update/conf error calling API: ", error );
            setPublishButtonText('ERRORE');
            setTimeout(() => setPublishButtonText('Pubblica'), 2000);
        }
    }

    const handleEditModelName = () => {
        setIsNameEditMode(true);
        setNewModelName(modelName);
    };

    const handleModelNameChange = (event) => {
        setNewModelName(event.target.value);
    };

    const handleModelNamePopup = () => {
        setIsNameEditPopup(true)
    };

    const handleConfirmModelName = () => {
        setIsNameEditPopup(false)
        setModelName(newModelName);

        /* AXIOS CHIAMATA RENAME */
        axios.post(APIURL + '/forum/models/rename', {
            uid: modelInfo.uid,
            name: newModelName
        },{
            headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id }
        })
        .then(response => {
            console.log(response);   
        })
        .catch(error => {
            console.error(error);
            if (error.response && error.response.status === 403) {
                console.log("YOU SHOULD LOGIN AGAIN")
                // navigate('/login');
            }
        });

        setIsNameEditMode(false);
    };

    const handleCancelModelName = () => {
        setIsNameEditPopup(false)
    };

    const handleDeleteModel = () => {
        setIsDeletePopup(true)
    }

    const handleConfirmDeleteModel = () => {
        
        handleDelete()
    };

    const handleCancelDeleteModel = () => {
        setIsDeletePopup(false)
    };

    const handleShowARQRCode = () =>{
        if(showQRVRCode){setShowQRVRCode(false)}
        setShowQRARCode((current) => !current)
    }
    
    const handleShowVRQRCode = () =>{
        if(showQRARCode){setShowQRARCode(false)}
        setShowQRVRCode((current) => !current)
    }

    const handleDelete = () => {
        axios
          .post(APIURL + `/portus/models/delete`, {
            userid: auth.id,
            uid: modelInfo.uid,
          }, {
            headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id }
          })
          .then((response) => {
            console.log("/portus/model/delete response:", response);
            navigate("/dashboard");
          })
          .catch((error) => {
            console.log("/portus/model/delete error:", error);
          });
      };

    const handleCopyTESTToClipboard = () => {
    const urlToCopy = `<iframe src="https://portlab.3danimate.it/${auth.id}/${modelInfo.uid}test" width="100%" height="100%"></iframe>`; // Replace with the URL you want to copy

    navigator.clipboard.writeText(urlToCopy)
        .then(() => {
        setCopiedtest(true); // Set copied state to true
        setTimeout(() => {
            setCopiedtest(false); // Reset copied state after 2 seconds
        }, 2000);
        })
        .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error);
        });
    }

    const handleCopyLIVEToClipboard = () => {
        const urlToCopy = `<iframe src="https://portlab.3danimate.it/${auth.id}/${modelInfo.uid}" width="100%" height="100%"></iframe>`; // Replace with the URL you want to copy
    
        navigator.clipboard.writeText(urlToCopy)
            .then(() => {
            setCopiedlive(true); // Set copied state to true
            setTimeout(() => {
                setCopiedlive(false); // Reset copied state after 2 seconds
            }, 2000);
            })
            .catch((error) => {
            console.error('Failed to copy URL to clipboard:', error);
        });
    }

    const handleShowTestCode = () =>{
        if(showProdCode){setShowProdCode(false)}
        setShowTestCode((current) => !current)
    }
    
    const handleShowProdCode = () =>{
        if(showTestCode){setShowTestCode(false)}
        setShowProdCode((current) => !current)
    }

    const handleAnalyticsClick = () => {
        console.log("handleAnalyticsClick")
        navigate('/analytics', {
          state: {
            fromModelPanel: true,
            selectedModel: location.state.modelInfo,
            selectedModelHDImage: location.state.selectedModelHDImage,
            selectedTab: '2'
          }
        });
    }

    return(
        <>
            <SidebarContainer>
                <Sidebar/>
            </SidebarContainer>
            <ViewContainer>
                <HeaderBar>
                    <BackButton to={"/dashboard"}>
                        <BackButtonIcon  src={backicon}></BackButtonIcon>
                    </BackButton>
                    <ModelTitle>
                        {isNameEditMode ? (
                        <input
                            type="text"
                            value={newModelName}
                            onChange={handleModelNameChange}
                            onBlur={handleModelNamePopup}
                            autoFocus
                        />
                        ) : (
                            newModelName
                        )}
                    </ModelTitle>
                    {isNameEditPopup ? (
                        <Dialog
                        title="Confirm Model Name Change"
                        children={`Are you sure you want to change the model name to "${newModelName}"?`}
                        onConfirm={handleConfirmModelName}
                        onCancel={handleCancelModelName}
                        />
                    ) : (<></>)}
                    {isDeletePopup ? (
                        <Dialog
                        title="Conferma"
                        children={"Sei sicuro di voler eliminare questo modello?"}
                        onConfirm={handleConfirmDeleteModel}
                        onCancel={handleCancelDeleteModel}
                        />
                    ) : (<></>)}
                </HeaderBar>
                <ContentWrapper>
                    <ContentSection>
                        <PreviewWrapper>
                            <FrameContainer>

                                    {showQRARCode ?  (
                                    <QRCodeContainer>
                                        <QRHeader>
                                            <QRIcon src={ARicon}/>
                                            <PremiumTag>Premium</PremiumTag>
                                        </QRHeader>
                                        <QRCodeWrapper>
                                            <QRCode value={qrARCodeUrl} />
                                        </QRCodeWrapper>
                                        <>Inquadra per visualizzare il modello in AR</>
                                        <br/>
                                        <>oppure condividi questo link: 
                                            <br/>
                                            <u>{qrARCodeUrl}</u>
                                        </>
                                        <QRCloseButton onClick={() => setShowQRARCode(false)}>chiudi</QRCloseButton>
                                    </QRCodeContainer>) : (<></>)}

                                    {showQRVRCode ?  (
                                    <QRCodeContainer>
                                        <QRHeader>
                                            <QRIcon src={VRicon}/>
                                            <PremiumTag>Premium</PremiumTag>
                                        </QRHeader>
                                        <QRCodeWrapper>
                                            <QRCode value={qrVRCodeUrl} />
                                        </QRCodeWrapper>
                                        <>Inquadra per visualizzare il modello in VR</>
                                        <br/>
                                        {/* PREVIOUS ORIGINAL LINKS for VR
                                            <QRdirectButton href={`https://sketchfab.com/models/${modelInfo.uid}/embed?&vr_quality=1.5&pratio=2.5&vr_scale_rtt=1&autostart=1&vr=1&maxDevicePixelRatio=1`} target="_blank" rel="noopener noreferrer">
                                                Low Quality VR
                                            </QRdirectButton>
                                            
                                            <QRdirectButton href={`https://sketchfab.com/models/${modelInfo.uid}/embed?&vr_quality=2.5&pratio=2.5&vr_scale_rtt=1&autostart=1&vr=1&maxDevicePixelRatio=1`} target="_blank" rel="noopener noreferrer">
                                                Medium Quality VR
                                            </QRdirectButton>
                                            
                                            <QRdirectButton href={`https://sketchfab.com/models/${modelInfo.uid}/embed?&vr_quality=3.5&pratio=2.5&vr_scale_rtt=1&autostart=1&vr=1&maxDevicePixelRatio=1`} target="_blank" rel="noopener noreferrer">
                                                High Quality VR
                                            </QRdirectButton>
                                        */}
                                        <QRdirectButton href={`https://portlab.3danimate.it/redirect/?id_utente=${auth.id}&id_modello=${modelInfo._id}&other_code=${modelInfo.uid}&ARorVR=VR&vr_quality=low`} target="_blank" rel="noopener noreferrer">
                                            Low Quality VR
                                        </QRdirectButton>
                                        
                                        <QRdirectButton href={`https://portlab.3danimate.it/redirect/?id_utente=${auth.id}&id_modello=${modelInfo._id}&other_code=${modelInfo.uid}&ARorVR=VR&vr_quality=medium`} target="_blank" rel="noopener noreferrer">
                                            Medium Quality VR
                                        </QRdirectButton>
                                        
                                        <QRdirectButton href={`https://portlab.3danimate.it/redirect/?id_utente=${auth.id}&id_modello=${modelInfo._id}&other_code=${modelInfo.uid}&ARorVR=VR&vr_quality=high`} target="_blank" rel="noopener noreferrer">
                                            High Quality VR
                                        </QRdirectButton>
                                        
                                        <>oppure condividi questo link: 
                                            <br/>
                                            <u>{qrVRCodeUrl}</u>
                                        </>
                                        <QRCloseButton onClick={() => setShowQRVRCode(false)}>chiudi</QRCloseButton>
                                    </QRCodeContainer>) : (<></>)}

                                    
                                    {showTestCode ?  (
                                    <QRCodeContainer>
                                        <QRHeader>
                                        </QRHeader>
                                        <>Puoi copiare e incollare il seguente codice all'interno di una tua pagina web facendo attenzione ai due valori di height e width a seconda delle tue necessità:</>
                                        <br/>
                                        <code>
                                            {`<iframe src="https://portlab.3danimate.it/${auth.id}/${modelInfo.uid}test" width="100%" height="100%"></iframe>`}
                                        </code>
                                        <EmbedButtonElement
                                            style={{
                                                backgroundColor: copiedtest ? 'green' : 'rgba(255,255,255,0.08)',
                                                transition: 'background-color 0.3s ease',
                                            }}
                                            onClick={handleCopyTESTToClipboard}
                                            >
                                            <CodeIcon src={codeicon}/>
                                            {copiedtest ? 'Copiato!' : 'Clicca per copiare'}
                                        </EmbedButtonElement>
                                        <br/>
                                        Per vedere in anteprima il risultato puoi visitare questa pagina: 
                                        <br/>
                                        <u>{`https://portlab.3danimate.it/${auth.id}/${modelInfo.uid}test`}</u>
                                        <QRCloseButton onClick={() => setShowTestCode(false)}>chiudi</QRCloseButton>
                                    </QRCodeContainer>) : (<></>)}
                                    
                                    {showProdCode ?  (
                                    <QRCodeContainer>
                                        <QRHeader>
                                        </QRHeader>
                                        <>Puoi copiare e incollare il seguente codice all'interno di una tua pagina web facendo attenzione ai due valori di height e width a seconda delle tue necessità:</>
                                        <br/>
                                        <code>
                                            {`<iframe src="https://portlab.3danimate.it/${auth.id}/${modelInfo.uid}" width="100%" height="100%"></iframe>`}
                                        </code>
                                        <EmbedButtonElement
                                            style={{
                                                backgroundColor: copiedlive ? 'green' : 'rgba(255,255,255,0.08)',
                                                transition: 'background-color 0.3s ease',
                                            }}
                                            onClick={handleCopyLIVEToClipboard}
                                            >
                                            <CodeIcon src={codeicon}/>
                                            {copiedlive ? 'Copiato!' : 'Clicca per copiare'}
                                        </EmbedButtonElement>
                                        <br/>
                                        Per vedere in anteprima il risultato puoi visitare questa pagina: 
                                        <br/>
                                        <u>{`https://portlab.3danimate.it/${auth.id}/${modelInfo.uid}`}</u>
                                        <QRCloseButton onClick={() => setShowProdCode(false)}>chiudi</QRCloseButton>
                                    </QRCodeContainer>) : (<></>)}

                            {/*<iframe src={modelInfo.sfDoc.embedUrl} width={"100%"} id="iframePreview"></iframe>*/}
                                <ConfigurationPreview UID={modelInfo.uid} JSONsettings={mode === 'prod' ? prodSettings : testSettings} id={modelInfo._id} key={refreshCount}/>
                            </FrameContainer>
                        </PreviewWrapper>
                        <LargeButtonSection>
                            <LargeButtonWrapper>
                                <LargeButtonElement onClick={() => setIsConfOpen(true)}>
                                    <LargeIconButton src={settingsicon}></LargeIconButton>
                                    Configurazioni
                                </LargeButtonElement>
                                <LargeButtonElement onClick={() => handleAnalyticsClick()}>
                                    <LargeIconButton src={analyticsicon}></LargeIconButton>
                                    Analytics
                                </LargeButtonElement>
                                <LargeButtonElement onClick={handleEditModelName}>
                                    <LargeIconButton src={renameicon}></LargeIconButton>
                                    Rinomina
                                </LargeButtonElement>
                                <LargeButtonElement onClick={handleDeleteModel}>
                                    <LargeIconButton src={deleteicon}></LargeIconButton>
                                    Elimina
                                </LargeButtonElement>
                            </LargeButtonWrapper>
                            <ModelDetails>
                                <ModelDetailRow>Vertex count: {modelInfo.sfDoc.vertexCount}</ModelDetailRow>
                                <ModelDetailRow>Face count: {modelInfo.sfDoc.faceCount}</ModelDetailRow>
                                <ModelDetailRow>Pubblicato il: {formatDate(modelInfo.sfDoc.publishedAt)}</ModelDetailRow>
                            </ModelDetails>

                            <PreviewButtonContainer>
                                Embed Code:
                                <QRButtonHalfContainer>
                                    <CodeButtonHalfLeft onClick={handleShowProdCode}>
                                    <CodeIcon src={codeicon}/>
                                    LIVE
                                    </CodeButtonHalfLeft>
                                    <CodeButtonHalfRight onClick={handleShowTestCode} >
                                    <CodeIcon src={codeicon}/>
                                    TEST
                                    </CodeButtonHalfRight>
                                </QRButtonHalfContainer>
                                <QRButtonHeader>QR Code: <PremiumTag>Premium</PremiumTag></QRButtonHeader>
                                {auth.roles === 2 ? (<>
                                <QRButtonHalfContainer>
                                    <QRButtonHalfLeft onClick={handleShowARQRCode}>
                                        <QRIcon src={ARicon}/>
                                        AR
                                    </QRButtonHalfLeft>
                                    <QRButtonHalfRight onClick={handleShowVRQRCode}>
                                        <QRIcon src={VRicon}/>
                                        VR
                                    </QRButtonHalfRight>
                                </QRButtonHalfContainer>
                                </>) : (<>
                                <PremiumButtonElement>
                                    funzionalità solo premium
                                </PremiumButtonElement>
                                </>)}
                                {/*<PreviewButtonElement to={`/output/${auth.id}/${location.state.modelInfo.uid}/${"test"}`} style={{backgroundColor: "#4F46E5"}} target="_blank">
                                    <PreviewButtonIcon  src={previewicon}/>
                                    Preview di Test
                                </PreviewButtonElement>*/}
                                <PreviewButtonElement to={`/output/${auth.id}/${location.state.modelInfo.uid}/${"prod"}/${newModelName}`} style={{backgroundColor: "#EA580C"}} 
                                target="_blank"
                                >
                                    <PreviewButtonIcon src={previewicon}/>
                                    Preview Live
                                </PreviewButtonElement>
                            </PreviewButtonContainer>
                        </LargeButtonSection>
                        {/* LIVE */}
                        { isConfOpen && mode == 'prod' ? (
                        <TabWrapper>
                            <HeaderConfigSection>
                                <HeaderConfigTitleRow>
                                    Configurazioni
                                    <CloseButton src={closeicon} onClick={() => setIsConfOpen(false)}/>
                                </HeaderConfigTitleRow>
                                <HeaderConfigToggle>
                                    <ConfigToggleElementRight
                                        onClick={() => handleModeChange('prod')}
                                        isActive={mode === 'prod'}
                                    >
                                        Ambiente Live
                                    </ConfigToggleElementRight>
                                    <ConfigToggleElementLeft
                                        onClick={() => handleModeChange('test')}
                                        isActive={mode === 'test'}
                                    >
                                        Ambiente di Test
                                    </ConfigToggleElementLeft>
                                </HeaderConfigToggle>
                            </HeaderConfigSection>
                            <CentralConfigSection>
                                {easyConfigProd ? (
                                <>
                                    <HorizontalTabContainer>
                                    </HorizontalTabContainer>
                                <TabContentView>
                                    <CategorySection>
                                        <InputContainer>
                                        <ConfigurationButton
                                        id="uicontrols"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={!prodSettings.uicontrols}
                                        onChange={handleProdValueChange}
                                        label="Rimuovi Tutta l'Interfaccia"
                                        description="Impostando a 1 si nasconderanno tutti i controlli del visualizzatore nella parte inferiore dello schermo (Aiuto, Impostazioni, Ispettore, VR, Schermo Intero, Annotazioni e Animazioni)."
                                        />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                            id="uigeneralcontrols"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.uigeneralcontrols}
                                            onChange={handleProdValueChange}
                                            label="Interfaccia Utente Controlli Generali"
                                            description="Impostando a 1 verranno mostrati tutti i controlli del visualizzatore nella parte inferiore destra dello schermo (Help, Settings, Inspector, VR, Fullscreen)."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                            id="uiannotations"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.uiannotations}
                                            onChange={handleProdValueChange}
                                            label="Mostra Annotations"
                                            description="Impostando a 1 verrano mostrate le Annotations del modello ove presenti."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                            id="uiannotationsmenu"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.uiannotationsmenu}
                                            onChange={handleProdValueChange}
                                            label="Mostra lo scorrimento e il menu delle Annotations"
                                            description="Impostando a 1 verrà mostrata l'interfaccia per scorrere le annotations presenti sul modello."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                        <ConfigurationButton
                                        id="uivr"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uivr}
                                        onChange={handleProdValueChange}
                                        label="UI VR - PREMIUM"
                                        description="Mostra il pulsante VR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiar"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uiar}
                                        onChange={handleProdValueChange}
                                        label="UI AR - PREMIUM"
                                        description="Mostra il pulsante AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    { /*
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiarqrcode"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uiarqrcode}
                                        onChange={handleProdValueChange}
                                        label="UI AR QR - PREMIUM"
                                        description="Mostra il QR code per l' AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    */ }
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="autospin"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.autospin}
                                        onChange={handleProdValueChange}
                                        label="Abilita Autospin"
                                        description="Abilita l'autospin sul modello"
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="spinspeed"
                                        mode={mode}
                                        type="number"
                                        initialState={prodSettings.spinspeed}
                                        min={-20}
                                        max={20}
                                        step={0.1}
                                        onChange={handleProdValueChange}
                                        label="Velocità di Rotazione Autospin"
                                        description="Imposta la velocità dell'autospin."
                                        disabled={!prodSettings.autospin}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                            <ConfigurationButton 
                                            id="iswidthconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.iswidthconstraintactive} 
                                            onChange={handleProdValueChange} 
                                            label="Attiva Limitazione Larghezza"
                                            description="Abilita la limitazione della larghezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="widthconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={prodSettings.widthconstraint}
                                            min={0}
                                            max={3840}
                                            step={1}
                                            onChange={handleProdValueChange}
                                            label="Imposta Larghezza"
                                            description="Imposta la larghezza dell'iframe."
                                            disabled={!prodSettings.iswidthconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="isheightconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.isheightconstraintactive} 
                                            onChange={handleProdValueChange} 
                                            label="Attiva Limitazione Altezza"
                                            description="Abilita la limitazione dell'altezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="heightconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={prodSettings.heightconstraint}
                                            min={0}
                                            max={2160}
                                            step={1}
                                            onChange={handleProdValueChange}
                                            label="Imposta Altezza"
                                            description="Imposta l'altezza dell'iframe."
                                            disabled={!prodSettings.isheightconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="backgroundtransparent"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.backgroundtransparent} 
                                            onChange={handleProdValueChange} 
                                            label="Sfondo Trasparente"
                                            description="Imposta lo sfondo come trasparente."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                                id="backgroundcolor"   
                                                mode={mode}
                                                type="colorpicker" 
                                                initialState={prodSettings.backgroundcolor} 
                                                onChange={handleProdValueChange} 
                                                label="Colore dello Sfondo"
                                                description="Imposta il colore dello sfondo."
                                                disabled={prodSettings.backgroundtransparent}
                                            />
                                        </InputContainer>
                                    </CategorySection>
                                </TabContentView>
                                <ConfigurationEasyToggle onClick={() => setEasyConfigProd(false)}>Vai alle configurazioni avanzate.</ConfigurationEasyToggle>
                                </>
                                ) : (<>
                                <HorizontalTabContainer>
                                    <HorizontalLeftArrow onClick={handleScrollLeft}>{"<"}</HorizontalLeftArrow>
                                    <TabSelectorContainer
                                        ref={containerRef}
                                        onMouseDown={handleMouseDown}
                                        onWheel={handleWheel}
                                    >
                                    {tabs.map((tab, index) => (
                                        <TabName
                                        key={index}
                                        isActive={index === activeTab}
                                        onClick={() => handleTabClick(index)}
                                        ref={tabRefs[index]} // Assign a ref to the tab element
                                        >
                                        {tab.title}
                                        </TabName>
                                    ))}
                                    </TabSelectorContainer>
                                    <HorizontalRightArrow onClick={handleScrollRight}>{">"}</HorizontalRightArrow> 
                                </HorizontalTabContainer>
                                <TabContentView>
                                {activeTab === 0 && (
                                <>
                                    <CategorySection>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="isloadingactive"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.isloadingactive}
                                        onChange={handleProdValueChange}
                                        label="Caricamento attivo"
                                        description="Attiva o disattiva la funzionalità di caricamento."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="userlogo"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.userlogo}
                                        onChange={handleProdValueChange}
                                        label="Logo dell'utente	"
                                        description="Mostra il logo del tuo profilo durante il caricamento."
                                        disabled={!prodSettings.isloadingactive}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="loadingtext"
                                        type="text"
                                        mode={mode}
                                        initialState={prodSettings.loadingtext}
                                        onChange={handleProdValueChange}
                                        label="Testo di caricamento"
                                        description="Mostra il testo durante il caricamento."
                                        disabled={!prodSettings.isloadingactive}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="autostart"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.autostart}
                                        onChange={handleProdValueChange}
                                        label="Avvio automatico"
                                        description="Attiva o disattiva l'avvio automatico dell'applicazione."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="cameraintrozoom"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.cameraintrozoom}
                                        onChange={handleProdValueChange}
                                        label="Zoom introduttivo della camera"
                                        description="Attiva o disattiva lo zoom introduttivo della camera."
                                        />
                                    </InputContainer>
                                    </CategorySection>
                                </>
                                )}

                                {activeTab === 1 && (
                                <>
                                    <CategorySection>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uicontrols"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={!prodSettings.uicontrols}
                                        onChange={handleProdValueChange}
                                        label="Rimuovi tutta l'interfaccia"
                                        description="Impostando a 1 si nasconderanno tutti i controlli del visualizzatore nella parte inferiore dello schermo (Aiuto, Impostazioni, Ispettore, VR, Schermo Intero, Annotazioni e Animazioni)."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uianimations"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uianimations}
                                        onChange={handleProdValueChange}
                                        label="Interfaccia Utente Animazioni"
                                        description="Mostra il pulsante animazioni nell'interfaccia."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uifullscreen"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uifullscreen}
                                        onChange={handleProdValueChange}
                                        label="Interfaccia Utente Fullscreen"
                                        description="Mostra il pulsante fullscreen nell'interfaccia."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uigeneralcontrols"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uigeneralcontrols}
                                        onChange={handleProdValueChange}
                                        label="Interfaccia Utente Controlli Generali"
                                        description="Impostando a 1 verranno mostrati tutti i controlli del visualizzatore nella parte inferiore destra dello schermo (Help, Settings, Inspector, VR, Fullscreen)."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiannotations"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uiannotations}
                                        onChange={handleProdValueChange}
                                        label="Mostra Annotations"
                                        description="Impostando a 1 verrano mostrate le Annotations del modello ove presenti."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiannotationsmenu"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uiannotationsmenu}
                                        onChange={handleProdValueChange}
                                        label="Mostra lo scorrimento e il menu delle Annotations"
                                        description="Impostando a 1 verrà mostrata l'interfaccia per scorrere le annotations presenti sul modello."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiloading"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uiloading}
                                        onChange={handleProdValueChange}
                                        label="Interfaccia Utente Caricamento"
                                        description="Mostra l'animazione originale di caricamento."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uivr"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uivr}
                                        onChange={handleProdValueChange}
                                        label="UI VR - PREMIUM"
                                        description="Mostra il pulsante VR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiar"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uiar}
                                        onChange={handleProdValueChange}
                                        label="UI AR - PREMIUM"
                                        description="Mostra il pulsante AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    { /*
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiarqrcode"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={prodSettings.uiarqrcode}
                                        onChange={handleProdValueChange}
                                        label="UI AR QR - PREMIUM"
                                        description="Mostra il QR code per l' AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    */ }
                                    </CategorySection>
                                </>
                                )} {activeTab === 2 && (
                                    <>
                                      <CategorySection>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="animationautoplay"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.animationautoplay}
                                            onChange={handleProdValueChange}
                                            label="Animation Autoplay"
                                            description="Autoplay the model's animation."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="autospin"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.autospin}
                                            onChange={handleProdValueChange}
                                            label="Enable Autospin"
                                            description="Enable Autospin on the model"
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="spinspeed"
                                            mode={mode}
                                            type="number"
                                            initialState={prodSettings.spinspeed}
                                            min={-20}
                                            max={20}
                                            step={0.1}
                                            onChange={handleProdValueChange}
                                            label="Spin Speed"
                                            description="Set the Autospin speed."
                                            disabled={!prodSettings.autospin}
                                          />
                                        </InputContainer>
                                      </CategorySection>
                                    </>
                                  )}

                                  
                                    { /*
                                  
                                  {activeTab === 3 && (
                                    <>
                                      <TextAreaInputContainer>
                                        <TextareaInputLabel>Input to change the 8 text areas</TextareaInputLabel>
                                        <TextareaInputButtonContainer>
                                            <TextareaSingleInputButton
                                            key={1}
                                            onClick={() => handleButtonClick(1)}
                                            >
                                                {visibleTextareaId == 1 && (<>
                                                    <TextAreaSelectedBox/>
                                                </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={2}
                                            onClick={() => handleButtonClick(2)}
                                            >
                                            {visibleTextareaId == 2 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={3}
                                            onClick={() => handleButtonClick(3)}
                                            >
                                            {visibleTextareaId == 3 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={4}
                                            onClick={() => handleButtonClick(4)}
                                            >
                                            {visibleTextareaId == 4 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleEmptyButton/>
                                            <TextareaSingleInputButton
                                            key={5}
                                            onClick={() => handleButtonClick(5)}
                                            >
                                            {visibleTextareaId == 5 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={6}
                                            onClick={() => handleButtonClick(6)}
                                            >
                                            {visibleTextareaId == 6 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={7}
                                            onClick={() => handleButtonClick(7)}
                                            >
                                            {visibleTextareaId == 7 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={8}
                                            onClick={() => handleButtonClick(8)}
                                            >
                                            {visibleTextareaId == 8 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                        </TextareaInputButtonContainer>
                                        <TextareaInput
                                        value={prodtextareaValues[visibleTextareaId]}
                                        onChange={(e) => handleProdtextareaChange(visibleTextareaId, e.target.value)}
                                        style={{ fontFamily: "monospace" }} // Use a monospaced font for HTML code
                                        />
                                        </TextAreaInputContainer>
                                    </>
                                  )}
                                                    RICAMBIARE LE SUCCESSIVE ACTIVETAB A INDICE +1
                                    */ }
                                  
                                  {activeTab === 3 && (
                                    <>
                                      <CategorySection>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="isfovactive"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.isfovactive}
                                            onChange={handleProdValueChange}
                                            label="Enable Custom Field of View"
                                            description="Attiva un valore di Campo Visivo personalizzato."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="fov"
                                            mode={mode}
                                            type="number"
                                            initialState={prodSettings.fov}
                                            min={0}
                                            max={180}
                                            step={1}
                                            onChange={handleProdValueChange}
                                            label="Field of View - value"
                                            description="Valore del Campo Visivo personalizzato. [ 0 - 180 ]"
                                            disabled={!prodSettings.isfovactive}
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="orbitcostrictionpan"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.orbitcostrictionpan}
                                            onChange={handleProdValueChange}
                                            label="Orbit Constriction Pan"
                                            description="Impedisci il pan ( trascinamento ) della camera."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="ispancameralimitactive"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.ispancameralimitactive}
                                            onChange={handleProdValueChange}
                                            label="Enable Pan Camera Limit"
                                            description="Abilita il limite di trascinamento verticale della camera."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="pancamerauplimit"
                                            mode={mode}
                                            type="number"
                                            initialState={prodSettings.pancamerauplimit}
                                            min={0}
                                            max={90}
                                            step={0.1}
                                            onChange={handleProdValueChange}
                                            label="Pitch Camera Range Top Limit"
                                            description="Limite di trascinamento superiore della camera."
                                            disabled={!prodSettings.ispancameralimitactive}
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="pancameradownlimit"
                                            mode={mode}
                                            type="number"
                                            initialState={prodSettings.pancameradownlimit}
                                            min={-90}
                                            max={0}
                                            step={0.1}
                                            onChange={handleProdValueChange}
                                            label="Pitch Camera Range Bottom Limit"
                                            description="Limite di trascinamento inferiore della camera."
                                            disabled={!prodSettings.ispancameralimitactive}
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="iscamerayawlimitactive"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={prodSettings.iscamerayawlimitactive}
                                            onChange={handleProdValueChange}
                                            label="Enable Yaw Camera Limit"
                                            description="Abilita il limite di trascinamento orizzontale della camera."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                                id="yawcameraleftlimit"
                                                mode={mode}
                                                type="number"
                                                initialState={prodSettings.yawcameraleftlimit}
                                                min={0}
                                                max={360}
                                                step={1}
                                                onChange={handleProdValueChange}
                                                label="Yaw Camera Range Left Limit (Offset)"
                                                description="Limite di trascinamento a sinistra della camera."
                                                disabled={!prodSettings.iscamerayawlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="yawcamerarightlimit"
                                                mode={mode}
                                                type="number"
                                                initialState={prodSettings.yawcamerarightlimit}
                                                min={0}
                                                max={360}
                                                step={1}
                                                onChange={handleProdValueChange}
                                                label="Yaw Camera Range Right Limit (Range)"
                                                description="Limite di trascinamento a destra della camera."
                                                disabled={!prodSettings.iscamerayawlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="iscamerazoomlimitactive"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={prodSettings.iscamerazoomlimitactive}
                                                onChange={handleProdValueChange}
                                                label="Enable Camera Zoom Limit"
                                                description="Abilita Limiti dello zoom."
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="camerazoomcloselimit"
                                                mode={mode}
                                                type="number"
                                                initialState={prodSettings.camerazoomcloselimit}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={handleProdValueChange}
                                                label="Camera Zoom Close Limit"
                                                description="Limite della vicinanza dello zoom."
                                                disabled={!prodSettings.iscamerazoomlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="camerazoomfarlimit"
                                                mode={mode}
                                                type="number"
                                                initialState={prodSettings.camerazoomfarlimit}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={handleProdValueChange}
                                                label="Camera Zoom Far Limit"
                                                description="Limite della lontananza dello zoom."
                                                disabled={!prodSettings.iscamerazoomlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="scrollwheel"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={prodSettings.scrollwheel}
                                                onChange={handleProdValueChange}
                                                label="Scroll Wheel"
                                                description="Abilita o disabilità l'uso della rotella del mouse per lo spostamento del modello 3d."
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="doubleclick"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={prodSettings.doubleclick}
                                                onChange={handleProdValueChange}
                                                label="Double Click"
                                                description="Abilita o disabilità il doppio click per centrare la camera."
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="dof"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={prodSettings.dof}
                                                onChange={handleProdValueChange}
                                                label="Depth of Field"
                                                description="Attiva la profondità di campo."
                                            />
                                            </InputContainer>
                                        </CategorySection>
                                        </>)
                                        } { activeTab === 4 && 
                                        (<>
                                        <CategorySection>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                id="isantialiasingactive"   
                                                mode={mode}
                                                type="checkbox" 
                                                initialState={prodSettings.isantialiasingactive} 
                                                onChange={handleProdValueChange} 
                                                label="Antialiasing"
                                                description="Ottimizzazione: Antialiasing."
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton
                                                    id="antialiasing"
                                                    type="dropdown"
                                                    mode={mode}
                                                    initialState={prodSettings.antialiasing}
                                                    onChange={handleProdValueChange}
                                                    options={['','TTA']}
                                                    label="Antialiasing Type"
                                                    description="Tipologia di Antialiasing."
                                                    disabled={!prodSettings.isantialiasingactive}
                                                />
                                            </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="preload"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.preload} 
                                            onChange={handleProdValueChange} 
                                            label="Preload"
                                            description="Ottimizzazione: Preload."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="mergematerials"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.mergematerials} 
                                            onChange={handleProdValueChange} 
                                            label="Merge Materials"
                                            description="Ottimizzazione: Merge Materials."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="graphoptimizer"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.graphoptimizer} 
                                            onChange={handleProdValueChange} 
                                            label="Graph Optimizer"
                                            description="Ottimizzazione: Graph Optimizer."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="materialpacking"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.materialpacking} 
                                            onChange={handleProdValueChange} 
                                            label="Material Packing"
                                            description="Ottimizzazione: Material Packing."
                                            />
                                        </InputContainer>
                                    </CategorySection>
                                        </>)
                                    } { activeTab === 5 && 
                                    (<>
                                    <CategorySection>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="iswidthconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.iswidthconstraintactive} 
                                            onChange={handleProdValueChange} 
                                            label="Width Constraint Active"
                                            description="Abilità Larghezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="widthconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={prodSettings.widthconstraint}
                                            min={0}
                                            max={3840}
                                            step={1}
                                            onChange={handleProdValueChange}
                                            label="Set Width"
                                            description="Larghezza dell'iframe."
                                            disabled={!prodSettings.iswidthconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="isheightconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.isheightconstraintactive} 
                                            onChange={handleProdValueChange} 
                                            label="Height Constraint Active"
                                            description="Abilità Altezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="heightconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={prodSettings.heightconstraint}
                                            min={0}
                                            max={2160}
                                            step={1}
                                            onChange={handleProdValueChange}
                                            label="Set Height"
                                            description="Altezza dell'iframe."
                                            disabled={!prodSettings.isheightconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="checkmobile"   
                                            ode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.checkmobile} 
                                            onChange={handleProdValueChange} 
                                            label="Mobile Device Check"
                                            description="Attiva il controllo per smartphone al fine di diminuire la qualità delle texture ed ottimizzare caricamento e visualizzazione."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                                id="texturequality"
                                                type="dropdown"
                                                mode={mode}
                                                initialState={prodSettings.texturequality}
                                                onChange={handleProdValueChange}
                                                options={['sd', 'md', 'hd']}
                                                label="Mobile Set Texture Quality"
                                                description="Imposta la qualità delle texture su smartphone."
                                                disabled={!prodSettings.checkmobile}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="backgroundtransparent"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.backgroundtransparent} 
                                            onChange={handleProdValueChange} 
                                            label="Background Transparent"
                                            description="Se attivo lo sfondo sarà trasparente."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="isoriginalbackground"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={prodSettings.isoriginalbackground} 
                                            onChange={handleProdValueChange} 
                                            label="Original Background"
                                            description="Utilizza il background originale della scena 3D importata."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                                id="backgroundcolor"   
                                                mode={mode}
                                                type="colorpicker" 
                                                initialState={prodSettings.backgroundcolor} 
                                                onChange={handleProdValueChange} 
                                                label="Background Color"
                                                description="Colore di sfondo."
                                                disabled={prodSettings.backgroundtransparent}
                                            />
                                        </InputContainer>
                                    </CategorySection>
                                    </>
                                    )} 
                                    { activeTab === 6 && 
                                    (<>
                                        <CategorySection>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="usehud"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={prodSettings.usehud} 
                                                    onChange={handleProdValueChange} 
                                                    label="Use New Hud Interface"
                                                    description="Activate to use the new hud interface for annotations and animations."
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showhudannotations"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={prodSettings.showhudannotations} 
                                                    onChange={handleProdValueChange} 
                                                    label="Show Annotations Interface"
                                                    description="Activate to use the new hud interface for annotations."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showannotationsnumbers"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={prodSettings.showannotationsnumbers} 
                                                    onChange={handleProdValueChange} 
                                                    label="Show Annotations Interface Numbers"
                                                    description="Activate to show annotationsì numbers inside circles."
                                                    disabled={!prodSettings.showhudannotations}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showhudanimations"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={prodSettings.showhudanimations} 
                                                    onChange={handleProdValueChange} 
                                                    label="Show Animations Interface"
                                                    description="Activate to use the new hud interface for animations."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showhudpositions"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={prodSettings.showhudpositions} 
                                                    onChange={handleProdValueChange} 
                                                    label="Show Positions Interface"
                                                    description="Attivare per usare la nuova interfaccia per le visualizzazioni dalle 4 posizioni in alto al centro."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="hudpositiondistance"   
                                                    mode={mode}
                                                    type="number" 
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    initialState={prodSettings.hudpositiondistance} 
                                                    onChange={handleProdValueChange} 
                                                    label="Hud Positions distance"
                                                    description="Specifica un numero per la distanza della videocamera impostata nelle 4 posizioni. [1-20]"
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="annotationdotcolor"   
                                                    mode={mode}
                                                    type="colorpicker" 
                                                    initialState={prodSettings.annotationdotcolor} 
                                                    onChange={handleProdValueChange} 
                                                    label="Colore dello Sfondo"
                                                    description="Imposta il colore dello sfondo dei Punti di Interesse."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="annotationtextcolor"   
                                                    mode={mode}
                                                    type="colorpicker" 
                                                    initialState={prodSettings.annotationtextcolor} 
                                                    onChange={handleProdValueChange} 
                                                    label="Colore dello Sfondo"
                                                    description="Imposta il colore dei numeri dei Punti di Interesse."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                        </CategorySection>
                                    </>)}
                                </TabContentView>
                                <ConfigurationEasyToggle onClick={() => setEasyConfigProd(true)}>Vai alle configurazioni semplici.</ConfigurationEasyToggle>
                                </>)}
                                
                            </CentralConfigSection>
                            <FooterConfigSection>
                                <FooterButtonConfigCOntainer>
                                    <SaveConfigButton onClick={() => handleSave()}>Salva</SaveConfigButton>
                                    <PublishConfigButton onClick={() => handlePublish(1)} mode={"prod"}>{publishButtonText}</PublishConfigButton>
                                </FooterButtonConfigCOntainer>
                            </FooterConfigSection>
                        </TabWrapper>
                        ) : isConfOpen && mode == 'test' ? (<>
                            <TabWrapper>
                            <HeaderConfigSection>
                                <HeaderConfigTitleRow>
                                    Configurazioni
                                    <CloseButton src={closeicon} onClick={() => setIsConfOpen(false)}/>
                                </HeaderConfigTitleRow>
                                <HeaderConfigToggle>
                                    <ConfigToggleElementRight
                                        onClick={() => handleModeChange('prod')}
                                        isActive={mode === 'prod'}
                                    >
                                        Ambiente Live
                                    </ConfigToggleElementRight>
                                    <ConfigToggleElementLeft
                                        onClick={() => handleModeChange('test')}
                                        isActive={mode === 'test'}
                                    >
                                        Ambiente di Test
                                    </ConfigToggleElementLeft>
                                </HeaderConfigToggle>
                            </HeaderConfigSection>
                            <CentralConfigSection>
                                {easyConfigTest ? (
                                <>
                                    <HorizontalTabContainer>
                                    </HorizontalTabContainer>
                                <TabContentView>
                                    <CategorySection>
                                        <InputContainer>
                                        <ConfigurationButton
                                        id="uicontrols"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={!testSettings.uicontrols}
                                        onChange={handleTestValueChange}
                                        label="Rimuovi Tutta l'Interfaccia"
                                        description="Impostando a 1 si nasconderanno tutti i controlli del visualizzatore nella parte inferiore dello schermo (Aiuto, Impostazioni, Ispettore, VR, Schermo Intero, Annotazioni e Animazioni)."
                                        />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                            id="uigeneralcontrols"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.uigeneralcontrols}
                                            onChange={handleTestValueChange}
                                            label="Interfaccia Utente Controlli Generali"
                                            description="Impostando a 1 verranno mostrati tutti i controlli del visualizzatore nella parte inferiore destra dello schermo (Help, Settings, Inspector, VR, Fullscreen)."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                            id="uiannotations"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.uiannotations}
                                            onChange={handleTestValueChange}
                                            label="Mostra Annotations"
                                            description="Impostando a 1 verrano mostrate le Annotations del modello ove presenti."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                            id="uiannotationsmenu"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.uiannotationsmenu}
                                            onChange={handleTestValueChange}
                                            label="Mostra lo scorrimento e il menu delle Annotations"
                                            description="Impostando a 1 verrà mostrata l'interfaccia per scorrere le annotations presenti sul modello."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                        <ConfigurationButton
                                        id="uivr"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uivr}
                                        onChange={handleTestValueChange}
                                        label="UI VR - PREMIUM"
                                        description="Mostra il pulsante VR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiar"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uiar}
                                        onChange={handleTestValueChange}
                                        label="UI AR - PREMIUM"
                                        description="Mostra il pulsante AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    { /*
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiarqrcode"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uiarqrcode}
                                        onChange={handleTestValueChange}
                                        label="UI AR QR - PREMIUM"
                                        description="Mostra il QR code per l' AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    */ }
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="autospin"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.autospin}
                                        onChange={handleTestValueChange}
                                        label="Abilita Autospin"
                                        description="Abilita l'autospin sul modello"
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="spinspeed"
                                        mode={mode}
                                        type="number"
                                        initialState={testSettings.spinspeed}
                                        min={-20}
                                        max={20}
                                        step={0.1}
                                        onChange={handleTestValueChange}
                                        label="Velocità di Rotazione Autospin"
                                        description="Imposta la velocità dell'autospin."
                                        disabled={!testSettings.autospin}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                            <ConfigurationButton 
                                            id="iswidthconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.iswidthconstraintactive} 
                                            onChange={handleTestValueChange} 
                                            label="Attiva Limitazione Larghezza"
                                            description="Abilita la limitazione della larghezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="widthconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={testSettings.widthconstraint}
                                            min={0}
                                            max={3840}
                                            step={1}
                                            onChange={handleTestValueChange}
                                            label="Imposta Larghezza"
                                            description="Imposta la larghezza dell'iframe."
                                            disabled={!testSettings.iswidthconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="isheightconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.isheightconstraintactive} 
                                            onChange={handleTestValueChange} 
                                            label="Attiva Limitazione Altezza"
                                            description="Abilita la limitazione dell'altezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="heightconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={testSettings.heightconstraint}
                                            min={0}
                                            max={2160}
                                            step={1}
                                            onChange={handleTestValueChange}
                                            label="Imposta Altezza"
                                            description="Imposta l'altezza dell'iframe."
                                            disabled={!testSettings.isheightconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="backgroundtransparent"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.backgroundtransparent} 
                                            onChange={handleTestValueChange} 
                                            label="Sfondo Trasparente"
                                            description="Imposta lo sfondo come trasparente."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                                id="backgroundcolor"   
                                                mode={mode}
                                                type="colorpicker" 
                                                initialState={testSettings.backgroundcolor} 
                                                onChange={handleTestValueChange} 
                                                label="Colore dello Sfondo"
                                                description="Imposta il colore dello sfondo."
                                                disabled={testSettings.backgroundtransparent}
                                            />
                                        </InputContainer>
                                    </CategorySection>
                                </TabContentView>
                                <ConfigurationEasyToggle onClick={() => setEasyConfigTest(false)}>Vai alle configurazioni avanzate.</ConfigurationEasyToggle>
                                </>
                                ) : (<>
<HorizontalTabContainer>
                                    <HorizontalLeftArrow onClick={handleScrollLeft}>{"<"}</HorizontalLeftArrow>
                                    <TabSelectorContainer
                                        ref={containerRef}
                                        onMouseDown={handleMouseDown}
                                        onWheel={handleWheel}
                                    >
                                    {tabs.map((tab, index) => (
                                        <TabName
                                        key={index}
                                        isActive={index === activeTab}
                                        onClick={() => handleTabClick(index)}
                                        ref={tabRefs[index]} // Assign a ref to the tab element
                                        >
                                        {tab.title}
                                        </TabName>
                                    ))}
                                    </TabSelectorContainer>
                                    <HorizontalRightArrow onClick={handleScrollRight}>{">"}</HorizontalRightArrow> 
                                </HorizontalTabContainer>
                                <TabContentView>
                                {activeTab === 0 && (
                                <>
                                    <CategorySection>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="isloadingactive"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.isloadingactive}
                                        onChange={handleTestValueChange}
                                        label="Caricamento attivo"
                                        description="Attiva o disattiva la funzionalità di caricamento."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="userlogo"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.userlogo}
                                        onChange={handleTestValueChange}
                                        label="Logo dell'utente	"
                                        description="Mostra il logo del tuo profilo durante il caricamento."
                                        disabled={!testSettings.isloadingactive}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="loadingtext"
                                        type="text"
                                        mode={mode}
                                        initialState={testSettings.loadingtext}
                                        onChange={handleTestValueChange}
                                        label="Testo di caricamento"
                                        description="Mostra il testo durante il caricamento."
                                        disabled={!testSettings.isloadingactive}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="autostart"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.autostart}
                                        onChange={handleTestValueChange}
                                        label="Avvio automatico"
                                        description="Attiva o disattiva l'avvio automatico dell'applicazione."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="cameraintrozoom"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.cameraintrozoom}
                                        onChange={handleTestValueChange}
                                        label="Zoom introduttivo della camera"
                                        description="Attiva o disattiva lo zoom introduttivo della camera."
                                        />
                                    </InputContainer>
                                    </CategorySection>
                                </>
                                )}

                                {activeTab === 1 && (
                                <>
                                    <CategorySection>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uicontrols"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uicontrols}
                                        onChange={handleTestValueChange}
                                        label="Rimuovi tutta l'interfaccia"
                                        description="Impostando a 1 si nasconderanno tutti i controlli del visualizzatore nella parte inferiore dello schermo (Aiuto, Impostazioni, Ispettore, VR, Schermo Intero, Annotazioni e Animazioni)."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uianimations"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uianimations}
                                        onChange={handleTestValueChange}
                                        label="Interfaccia Utente Animazioni"
                                        description="Mostra il pulsante animazioni nell'interfaccia."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uifullscreen"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uifullscreen}
                                        onChange={handleTestValueChange}
                                        label="Interfaccia Utente Fullscreen"
                                        description="Mostra il pulsante fullscreen nell'interfaccia."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uigeneralcontrols"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uigeneralcontrols}
                                        onChange={handleTestValueChange}
                                        label="Interfaccia Utente Controlli Generali"
                                        description="Impostando a 1 verranno mostrati tutti i controlli del visualizzatore nella parte inferiore destra dello schermo (Help, Settings, Inspector, VR, Fullscreen)."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                            <ConfigurationButton
                                            id="uiannotations"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.uiannotations}
                                            onChange={handleTestValueChange}
                                            label="Mostra Annotations"
                                            description="Impostando a 1 verrano mostrate le Annotations del modello ove presenti."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                            id="uiannotationsmenu"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.uiannotationsmenu}
                                            onChange={handleTestValueChange}
                                            label="Mostra lo scorrimento e il menu delle Annotations"
                                            description="Impostando a 1 verrà mostrata l'interfaccia per scorrere le annotations presenti sul modello."
                                            />
                                        </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiloading"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uiloading}
                                        onChange={handleTestValueChange}
                                        label="Interfaccia Utente Caricamento"
                                        description="Mostra l'animazione originale di caricamento."
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uivr"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uivr}
                                        onChange={handleTestValueChange}
                                        label="UI VR - PREMIUM"
                                        description="Mostra il pulsante VR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiar"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uiar}
                                        onChange={handleTestValueChange}
                                        label="UI AR - PREMIUM"
                                        description="Mostra il pulsante AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    { /*
                                    <InputContainer>
                                        <ConfigurationButton
                                        id="uiarqrcode"
                                        mode={mode}
                                        type="checkbox"
                                        initialState={testSettings.uiarqrcode}
                                        onChange={handleTestValueChange}
                                        label="UI AR QR - PREMIUM"
                                        description="Mostra il QR code per l' AR nell'interfaccia."
                                        premium="premium"
                                        disabled={auth.roles === 1}
                                        />
                                    </InputContainer>
                                    */ }
                                    </CategorySection>
                                </>
                                )} {activeTab === 2 && (
                                    <>
                                      <CategorySection>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="animationautoplay"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.animationautoplay}
                                            onChange={handleTestValueChange}
                                            label="Animation Autoplay"
                                            description="Autoplay the model's animation."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="autospin"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.autospin}
                                            onChange={handleTestValueChange}
                                            label="Enable Autospin"
                                            description="Enable Autospin on the model"
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="spinspeed"
                                            mode={mode}
                                            type="number"
                                            initialState={testSettings.spinspeed}
                                            min={-20}
                                            max={20}
                                            step={0.1}
                                            onChange={handleTestValueChange}
                                            label="Spin Speed"
                                            description="Set the Autospin speed."
                                            disabled={!testSettings.autospin}
                                          />
                                        </InputContainer>
                                      </CategorySection>
                                    </>
                                  )}

                                  
                                    { /*
                                  
                                  {activeTab === 3 && (
                                    <>
                                      <TextAreaInputContainer>
                                        <TextareaInputLabel>Input to change the 8 text areas</TextareaInputLabel>
                                        <TextareaInputButtonContainer>
                                            <TextareaSingleInputButton
                                            key={1}
                                            onClick={() => handleButtonClick(1)}
                                            >
                                                {visibleTextareaId == 1 && (<>
                                                    <TextAreaSelectedBox/>
                                                </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={2}
                                            onClick={() => handleButtonClick(2)}
                                            >
                                            {visibleTextareaId == 2 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={3}
                                            onClick={() => handleButtonClick(3)}
                                            >
                                            {visibleTextareaId == 3 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={4}
                                            onClick={() => handleButtonClick(4)}
                                            >
                                            {visibleTextareaId == 4 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleEmptyButton/>
                                            <TextareaSingleInputButton
                                            key={5}
                                            onClick={() => handleButtonClick(5)}
                                            >
                                            {visibleTextareaId == 5 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={6}
                                            onClick={() => handleButtonClick(6)}
                                            >
                                            {visibleTextareaId == 6 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={7}
                                            onClick={() => handleButtonClick(7)}
                                            >
                                            {visibleTextareaId == 7 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                            <TextareaSingleInputButton
                                            key={8}
                                            onClick={() => handleButtonClick(8)}
                                            >
                                            {visibleTextareaId == 8 && (<>
                                                <TextAreaSelectedBox/>
                                            </>)}
                                            </TextareaSingleInputButton>
                                        </TextareaInputButtonContainer>
                                        <TextareaInput
                                        value={testtextareaValues[visibleTextareaId]}
                                        onChange={(e) => handleTesttextareaChange(visibleTextareaId, e.target.value)}
                                        style={{ fontFamily: "monospace" }} // Use a monospaced font for HTML code
                                        />
                                        </TextAreaInputContainer>
                                    </>
                                  )}

                                  
                                */ }
                                  
                                  {activeTab === 3 && (
                                    <>
                                      <CategorySection>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="isfovactive"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.isfovactive}
                                            onChange={handleTestValueChange}
                                            label="Enable Custom Field of View"
                                            description="Attiva un valore di Campo Visivo personalizzato."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="fov"
                                            mode={mode}
                                            type="number"
                                            initialState={testSettings.fov}
                                            min={0}
                                            max={180}
                                            step={1}
                                            onChange={handleTestValueChange}
                                            label="Field of View - value"
                                            description="Valore del Campo Visivo personalizzato. [ 0 - 180 ]"
                                            disabled={!testSettings.isfovactive}
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="orbitcostrictionpan"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.orbitcostrictionpan}
                                            onChange={handleTestValueChange}
                                            label="Orbit Constriction Pan"
                                            description="Impedisci il pan ( trascinamento ) della camera."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="ispancameralimitactive"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.ispancameralimitactive}
                                            onChange={handleTestValueChange}
                                            label="Enable Pan Camera Limit"
                                            description="Abilita il limite di trascinamento verticale della camera."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="pancamerauplimit"
                                            mode={mode}
                                            type="number"
                                            initialState={testSettings.pancamerauplimit}
                                            min={0}
                                            max={90}
                                            step={0.1}
                                            onChange={handleTestValueChange}
                                            label="Pitch Camera Range Top Limit"
                                            description="Limite di trascinamento superiore della camera."
                                            disabled={!testSettings.ispancameralimitactive}
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="pancameradownlimit"
                                            mode={mode}
                                            type="number"
                                            initialState={testSettings.pancameradownlimit}
                                            min={-90}
                                            max={0}
                                            step={0.1}
                                            onChange={handleTestValueChange}
                                            label="Pitch Camera Range Bottom Limit"
                                            description="Limite di trascinamento inferiore della camera."
                                            disabled={!testSettings.ispancameralimitactive}
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                          <ConfigurationButton
                                            id="iscamerayawlimitactive"
                                            mode={mode}
                                            type="checkbox"
                                            initialState={testSettings.iscamerayawlimitactive}
                                            onChange={handleTestValueChange}
                                            label="Enable Yaw Camera Limit"
                                            description="Abilita il limite di trascinamento orizzontale della camera."
                                          />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                                id="yawcameraleftlimit"
                                                mode={mode}
                                                type="number"
                                                initialState={testSettings.yawcameraleftlimit}
                                                min={0}
                                                max={360}
                                                step={1}
                                                onChange={handleTestValueChange}
                                                label="Yaw Camera Range Left Limit (Offset)"
                                                description="Limite di trascinamento a sinistra della camera."
                                                disabled={!testSettings.iscamerayawlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="yawcamerarightlimit"
                                                mode={mode}
                                                type="number"
                                                initialState={testSettings.yawcamerarightlimit}
                                                min={0}
                                                max={360}
                                                step={1}
                                                onChange={handleTestValueChange}
                                                label="Yaw Camera Range Right Limit (Range)"
                                                description="Limite di trascinamento a destra della camera."
                                                disabled={!testSettings.iscamerayawlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="iscamerazoomlimitactive"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={testSettings.iscamerazoomlimitactive}
                                                onChange={handleTestValueChange}
                                                label="Enable Camera Zoom Limit"
                                                description="Abilita Limiti dello zoom."
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="camerazoomcloselimit"
                                                mode={mode}
                                                type="number"
                                                initialState={testSettings.camerazoomcloselimit}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={handleTestValueChange}
                                                label="Camera Zoom Close Limit"
                                                description="Limite della vicinanza dello zoom."
                                                disabled={!testSettings.iscamerazoomlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="camerazoomfarlimit"
                                                mode={mode}
                                                type="number"
                                                initialState={testSettings.camerazoomfarlimit}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={handleTestValueChange}
                                                label="Camera Zoom Far Limit"
                                                description="Limite della lontananza dello zoom."
                                                disabled={!testSettings.iscamerazoomlimitactive}
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="scrollwheel"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={testSettings.scrollwheel}
                                                onChange={handleTestValueChange}
                                                label="Scroll Wheel"
                                                description="Abilita o disabilità l'uso della rotella del mouse per lo spostamento del modello 3d."
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="doubleclick"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={testSettings.doubleclick}
                                                onChange={handleTestValueChange}
                                                label="Double Click"
                                                description="Abilita o disabilità il doppio click per centrare la camera."
                                            />
                                            </InputContainer>
                                            <InputContainer>
                                            <ConfigurationButton
                                                id="dof"
                                                mode={mode}
                                                type="checkbox"
                                                initialState={testSettings.dof}
                                                onChange={handleTestValueChange}
                                                label="Depth of Field"
                                                description="Attiva la profondità di campo."
                                            />
                                            </InputContainer>
                                        </CategorySection>
                                        </>)
                                        } { activeTab === 4 && 
                                        (<>
                                        <CategorySection>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                id="isantialiasingactive"   
                                                mode={mode}
                                                type="checkbox" 
                                                initialState={testSettings.isantialiasingactive} 
                                                onChange={handleTestValueChange} 
                                                label="Antialiasing"
                                                description="Ottimizzazione: Antialiasing."
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton
                                                    id="antialiasing"
                                                    type="dropdown"
                                                    mode={mode}
                                                    initialState={testSettings.antialiasing}
                                                    onChange={handleTestValueChange}
                                                    options={['','TTA']}
                                                    label="Antialiasing Type"
                                                    description="Tipologia di Antialiasing."
                                                    disabled={!testSettings.isantialiasingactive}
                                                />
                                            </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="preload"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.preload} 
                                            onChange={handleTestValueChange} 
                                            label="Preload"
                                            description="Ottimizzazione: Preload."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="mergematerials"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.mergematerials} 
                                            onChange={handleTestValueChange} 
                                            label="Merge Materials"
                                            description="Ottimizzazione: Merge Materials."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="graphoptimizer"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.graphoptimizer} 
                                            onChange={handleTestValueChange} 
                                            label="Graph Optimizer"
                                            description="Ottimizzazione: Graph Optimizer."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="materialpacking"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.materialpacking} 
                                            onChange={handleTestValueChange} 
                                            label="Material Packing"
                                            description="Ottimizzazione: Material Packing."
                                            />
                                        </InputContainer>
                                    </CategorySection>
                                        </>)
                                    } { activeTab === 5 && 
                                    (<>
                                    <CategorySection>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="iswidthconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.iswidthconstraintactive} 
                                            onChange={handleTestValueChange} 
                                            label="Width Constraint Active"
                                            description="Abilità Larghezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="widthconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={testSettings.widthconstraint}
                                            min={0}
                                            max={3840}
                                            step={1}
                                            onChange={handleTestValueChange}
                                            label="Set Width"
                                            description="Larghezza dell'iframe."
                                            disabled={!testSettings.iswidthconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="isheightconstraintactive"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.isheightconstraintactive} 
                                            onChange={handleTestValueChange} 
                                            label="Height Constraint Active"
                                            description="Abilità Altezza dell'iframe."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="heightconstraint"   
                                            ode={mode}
                                            type="number" 
                                            initialState={testSettings.heightconstraint}
                                            min={0}
                                            max={2160}
                                            step={1}
                                            onChange={handleTestValueChange}
                                            label="Set Height"
                                            description="Altezza dell'iframe."
                                            disabled={!testSettings.isheightconstraintactive}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="checkmobile"   
                                            ode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.checkmobile} 
                                            onChange={handleTestValueChange} 
                                            label="Mobile Device Check"
                                            description="Attiva il controllo per smartphone al fine di diminuire la qualità delle texture ed ottimizzare caricamento e visualizzazione."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton
                                                id="texturequality"
                                                type="dropdown"
                                                mode={mode}
                                                initialState={testSettings.texturequality}
                                                onChange={handleTestValueChange}
                                                options={['sd', 'md', 'hd']}
                                                label="Mobile Set Texture Quality"
                                                description="Imposta la qualità delle texture su smartphone."
                                                disabled={!testSettings.checkmobile}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="backgroundtransparent"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.backgroundtransparent} 
                                            onChange={handleTestValueChange} 
                                            label="Background Transparent"
                                            description="Se attivo lo sfondo sarà trasparente."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                            id="isoriginalbackground"   
                                            mode={mode}
                                            type="checkbox" 
                                            initialState={testSettings.isoriginalbackground} 
                                            onChange={handleTestValueChange} 
                                            label="Original Background"
                                            description="Utilizza il background originale della scena 3D importata."
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <ConfigurationButton 
                                                id="backgroundcolor"   
                                                mode={mode}
                                                type="colorpicker" 
                                                initialState={testSettings.backgroundcolor} 
                                                onChange={handleTestValueChange} 
                                                label="Background Color"
                                                description="Background color."
                                                disabled={testSettings.backgroundtransparent}
                                            />
                                        </InputContainer>
                                    </CategorySection>
                                    </>)
                                    }
                                    { activeTab === 6 && 
                                    (<>
                                        <CategorySection>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="usehud"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={testSettings.usehud} 
                                                    onChange={handleTestValueChange} 
                                                    label="Use New Hud Interface"
                                                    description="Activate to use the new hud interface for annotations and animations."
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showhudannotations"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={testSettings.showhudannotations} 
                                                    onChange={handleTestValueChange} 
                                                    label="Show Annotations Interface"
                                                    description="Activate to use the new hud interface for annotations."
                                                    disabled={testSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showannotationsnumbers"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={testSettings.showannotationsnumbers} 
                                                    onChange={handleTestValueChange} 
                                                    label="Show Annotations Interface Numbers"
                                                    description="Activate to show annotationsì numbers inside circles."
                                                    disabled={testSettings.showhudannotations}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showhudanimations"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={testSettings.showhudanimations} 
                                                    onChange={handleTestValueChange} 
                                                    label="Show Animations Interface"
                                                    description="Activate to use the new hud interface for animations."
                                                    disabled={testSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="showhudpositions"   
                                                    mode={mode}
                                                    type="checkbox" 
                                                    initialState={testSettings.shshowhudpositionsowhudanimations} 
                                                    onChange={handleTestValueChange} 
                                                    label="Show Positions Interface"
                                                    description="Attivare per usare la nuova interfaccia per le visualizzazioni dalle 4 posizioni in alto al centro."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="hudpositiondistance"   
                                                    mode={mode}
                                                    type="number" 
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    initialState={testSettings.hudpositiondistance} 
                                                    onChange={handleTestValueChange} 
                                                    label="Hud Positions distance"
                                                    description="Specifica un numero per la distanza della videocamera impostata nelle 4 posizioni. [1-20]"
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="annotationdotcolor"   
                                                    mode={mode}
                                                    type="colorpicker" 
                                                    initialState={testSettings.annotationdotcolor} 
                                                    onChange={handleTestValueChange} 
                                                    label="Colore dello Sfondo"
                                                    description="Imposta il colore dello sfondo dei Punti di Interesse."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <ConfigurationButton 
                                                    id="annotationtextcolor"   
                                                    mode={mode}
                                                    type="colorpicker" 
                                                    initialState={testSettings.annotationtextcolor} 
                                                    onChange={handleTestValueChange} 
                                                    label="Colore dello Sfondo"
                                                    description="Imposta il colore dei numeri dei Punti di Interesse."
                                                    disabled={!prodSettings.usehud}
                                                />
                                            </InputContainer>
                                        </CategorySection>
                                    </>)}
                                </TabContentView>
                                <ConfigurationEasyToggle onClick={() => setEasyConfigTest(true)}>Vai alle configurazioni semplici.</ConfigurationEasyToggle>
                                </>)}
                                
                            </CentralConfigSection>
                            <FooterConfigSection>
                                <FooterButtonConfigCOntainer>
                                    <SaveConfigButton onClick={() => handleSave()}>Salva</SaveConfigButton>
                                    <PublishConfigButton onClick={() => handlePublish(0)} mode={"test"}>{publishButtonText}</PublishConfigButton>
                                </FooterButtonConfigCOntainer>
                            </FooterConfigSection>
                        </TabWrapper>
                            </>)
                         
                        : (<></>)
                        }
                    </ContentSection>
                </ContentWrapper>
            </ViewContainer>
        </>
    )
}

export default ConfigurationPage;