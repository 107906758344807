import React, { useState, useEffect, useRef } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth.js';
import QRCode from "qrcode.react";

import {centeredLoading,ARbutton, VRbutton, ButtonContainer, DirectionButton, ButtonBorder,
  DirectionButtonsContainer, ArVrPopupContainer, ArVrPopupContent, BodyPopup, CloseButtonPopup, FooterPopup,
  HeaderPopup, LinkButtonPopup, QRCodePopup, TitlePopup, AnnotationContainer, AnnotationsContainer,
  IFrame, HudBigButton, OpenMenuButton, CloseMenuButton, HudSpacer, 
  ExpandedSection, SectionTooltip, ButtonHover, IconHover, 
  IconTooltip, HudSmallButton,AnnotationHudBody,AnnotationHudContainer,AnnotationHudFooter,
  AnnotationHudFooterButton,AnnotationHudFooterButtonContainer,AnnotationHudHead,AnnotationHudHeadClose
  ,AnnotationHudTitle,AnnotationHudWrapper,AnnotationHudNumber, AnnotationHudFrame,
  AnnotationHudExternalHead, AnnotationHudFooterNextButton, AnnotationHudFooterPreviousButton
  ,CloseHudButton, ButtonPositionsContainer, ButtonPosition,ButtonResetPosition} from './configurationpreviewElements';

// import fronticon from '../../img/fronticon.png';
// import lefticon from '../../img/lefticon.png';
// import righticon from '../../img/righticon.png';
// import topicon from '../../img/topicon.png';

import pauseicon from '../../img/hud-preview/pause.svg';
import ar from '../../img/hud-preview/ar.svg';
import vr from '../../img/hud-preview/vr.svg';
import prev from '../../img/hud-preview/left-ann.svg';
import next from '../../img/hud-preview/right-ann.svg';
import prevhud from '../../img/hud-preview/prev-small.svg';
import nexthud from '../../img/hud-preview/next-small.svg';
import close from  '../../img/hud-preview/close.svg';
import menu from '../../img/hud-preview/menu.svg';
import fullscreen from '../../img/hud-preview/fullscreen.svg';
import playicon from '../../img/hud-preview/play.svg';
import annotation from '../../img/hud-preview/annotation.svg';
import animation from '../../img/hud-preview/animation.svg';
import posizione1 from '../../img/hud-preview/left.svg';
import posizione2 from '../../img/hud-preview/top.svg';
import posizione3 from '../../img/hud-preview/right.svg';
import posizione4 from '../../img/hud-preview/front.svg';
import qr from '../../img/hud-preview/qrcodes.svg';

const ConfigurationPreview = ({UID, JSONsettings, id}) => {
  const [settings, setSettings] = useState(JSONsettings);
  const iframeRef = useRef(null);
  const [coordinates, setCoordinates] = useState([]);
  const [isIframeLoaded, setIframeLoaded] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [arviews, setArviews] = useState(0);
  const [vrviews, setVRviews] = useState(0);
  const [annotations, setAnnotations] = useState([]);
  const [annotationsInfo, setAnnotationsInfo] = useState([]);
  const [animations, setAnimations] = useState([]);
  const [currentAnnotation, setCurrentAnnotation] = useState(-1);
  const [currentAnimation, setCurrentAnimation] = useState(0);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(true);
  const [isCameraMoving, setIsCameraMoving] = useState(false);
  const [isHudVisible, setIsHudVisible] = useState(true);
  const [isAnnotationHudVisible, setIsAnnotationHudVisible] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const [tooltipText, setTooltipText] = useState('');
  const { auth } = useAuth();
  const [apiInstance, setApiInstance] = useState(null);
  const [lastSuccessfulRequestTime, setLastSuccessfulRequestTime] = useState(Date.now());
  const [popupType, setPopupType] = useState(null); // 'AR', 'VR', or null
  const [currentVisibleAnimation, setCurrentVisibleAnimation] = useState(0);
  const [selectedAnnotationNumber, setSelectedAnnotationNumber] = useState('');
  const [selectedAnnotationTitle, setSelectedAnnotationTitle] = useState('');
  const [selectedAnnotationBody, setSelectedAnnotationBody] = useState('');
  const [hoverText, setHoverText] = useState('');
  const [hoverIcon, setHoverIcon] = useState(null);

  // const apiURL = 'https://test2.3dabackend.com';
  const apiURL = `${process.env.REACT_APP_API_BASE_URL}`

  const [tempData, setTempData] = useState({
    views: 0,
    views_ar: 0,
    views_vr: 0,
    interactions: 0
  });

  // console.log("id: " + id)
  // console.log("Configuration Preview JSON Settings: " ,settings);

  const handleIframeLoad = (etime) => {
    const newSessionId = (Date.now()).toString();
    // console.log("iframe ready");
    setSessionId(newSessionId);
    setIframeLoaded(true);          

    // axios call loading time 
    axios.post(apiURL + '/tacito/model', {
      "id_sessione": newSessionId,
      "id_modello": id, 
      "id_utente": auth?.id,
      "views": 1,
      "views_ar": arviews,
      "views_vr": vrviews,
      "interactions" : clickCount,
      "load_time" : etime,
      clicks: clickCount
    }, {
      headers: { 
        'Authorization': `Bearer ${auth?.accessToken}`, 
        'id': auth.id,
        '3d-portlab-api-key': '22646265-e26f-4158-92c6-db812bdf9524'
    }
    })
    .then(response => {
      console.log('Session response:', response.data);
    })
    .catch(error => {
      console.error('Error sending /tacito/model request:', error);
    });
  };

  useEffect(() => {  
    // console.log("------DATA inside session-------" + clickCount + " " + arviews + " " + vrviews + " " , tempData)
    
    const intervalId = setInterval(() => {
      if (clickCount > 0 || arviews > 0 || vrviews > 0) {
        // Calculate the elapsed time
        const currentTime = Date.now();
        const timePassed = currentTime - lastSuccessfulRequestTime;
        //console.log("Elapsed time:", elapsedTime);  // Log the elapsed time      
        const currentClickCount = clickCount;
        const currentArviews = arviews;
        const currentVrviews = vrviews;
        
        axios.post(apiURL + '/tacito/model', {
          "id_sessione": sessionId,
          "id_modello": id, 
          "id_utente": auth?.id,
          "views": 0,
          "views_ar": currentArviews + tempData.views_ar,
          "views_vr": currentVrviews + tempData.views_vr,
          "interactions": currentClickCount + tempData.interactions,
          //"load_time" : loadTime,
          clicks: clickCount
        }, {
          headers: { 
            'Authorization': `Bearer ${auth?.accessToken}`, 
            'id': auth.id,
            '3d-portlab-api-key': '22646265-e26f-4158-92c6-db812bdf9524'
        }
        })
        .then(response => {
          //console.log('Click count response:', response.data);
          if (response.status === 200 || response.data.success) {
            setArviews(0);
            setVRviews(0);
            setClickCount(0);
            setTempData(prevData => ({
              ...prevData,
              views_ar: 0,
              views_vr: 0,
              interactions: 0
            }));
          }
        })
        .catch(error => {
          console.error('Error sending /tacito/model temporal request:', error);
          setTempData(prevData => ({
            ...prevData,
            views_ar: prevData.views_ar + currentArviews,
            views_vr: prevData.views_vr + currentVrviews,
            interactions: prevData.interactions + currentClickCount
          }));
          console.log("/tacito/model axios post error.")
        });
      }
    }, 3000);
  
    return () => {
      clearInterval(intervalId);
    };
  }, [clickCount, arviews, vrviews, isIframeLoaded]);  

    useEffect(() => {
      if(!isIframeLoaded) return;
      const intervalId2 = setInterval(() => {
        
      //axios.post(apiURL + '/tacito/session/', {
      axios.post(apiURL + '/tacito/session', {
        "id_sessione": sessionId,
        "id_modello": id, 
        "id_utente": auth?.id,
        "view_time": 3000,
      }, {
        headers: { 
          'Authorization': `Bearer ${auth?.accessToken}`, 
          'id': auth.id,
          '3d-portlab-api-key': '22646265-e26f-4158-92c6-db812bdf9524' 
        }
      })
      .then(response => {
        // console.log('/tacito/session/ Session response:', response.data);
      })
      .catch(error => {
        console.error('Error sending /tacito/session/ temporal request:', error);
      });
    },3000);

    return () => {
      clearInterval(intervalId2);
    };

  },[isIframeLoaded])
  
  useEffect(() => {
    // console.log("heatmap coordinates: " + coordinates);
    const intervalId3 = setInterval(() => {
      if (coordinates.length > 0) {

        // Transform coordinates into the expected format
        const transformedCoordinates = coordinates.map(coord => ({
          x: coord[0],
          y: coord[1],
          value: 1
        }));

        axios.post(apiURL + '/tacito/heatmap', {
          "id_modello": id, 
          "id_utente": auth?.id,
          "data": transformedCoordinates
        }, {
          headers: { 
            'Authorization': `Bearer ${auth?.accessToken}`, 
            'id': auth.id,
            '3d-portlab-api-key': '22646265-e26f-4158-92c6-db812bdf9524'
          }
        })
          .then(response => {
            // console.log('Coordinates response:', response.data);
            setCoordinates([]);  // Reset the coordinates array after successfully sending data
          })
          .catch(error => {
            console.error('Error sending coordinates:', error);
          });
      }
    }, 3000);

    return () => {
      clearInterval(intervalId3);
    };
  }, [coordinates]);

  function computePastilles(wCanvas, hCanvas, bgColor, bgBorderColor, fgColor, fgBorderColor, text, numHotspot, wPastille, hPastille) {
    var wSize = wPastille / 10.0;
    var col = wCanvas / wSize;
    var row = hCanvas / wSize;
    var padding = 2;
    var w = wSize - padding;
    var cx;
    var cy = w * 0.5;
    var ty = cy + 8;
    var pastille = '';
    var num = 0;
  
    for (var i = 0; i < row; i++) {
      cx = wSize * 0.5;
      for (var k = 0; k < col; k++) {
        num++;
        var letters = text === 0 ? num : text[num];
        // Making circles and text invisible and 1px in size
        var circle = `<circle cx="2" cy="2" r="0.5" fill="rgba(0,0,0,1)" stroke="rgba(0,0,0,1)" stroke-width="0"/>`;
        var textVG = `<text font-size="1" stroke="rgba(0,0,0,1)" fill="rgba(0,0,0,1)" font-family="sans-serif" text-anchor="middle" alignment-baseline="baseline" x="${cx}" y="${ty}">${letters}</text>`;
        pastille += circle + textVG;
        cx += wSize;
      }
      cy += wSize;
      ty += wSize;
    }
  
    var s = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    s.setAttribute('version', '1.1');
    s.setAttribute('baseProfile', 'full');
    s.setAttribute('width', wPastille);
    s.setAttribute('height', hPastille);
    s.setAttribute('viewBox', `0 0 ${wPastille} ${hPastille}`);
    s.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    s.innerHTML = pastille;
    
    // make it base64
    var svg64 = btoa(s.outerHTML);
    var b64Start = 'data:image/svg+xml;base64,';
    var image64 = b64Start + svg64;
    var textureOptions = {
      url: image64,
      colNumber: col,
      padding: padding,
      iconSize: w
    };
    return textureOptions;
  }

  function getNewPastilleURL(bgColor, bgBorderColor, fgColor, fgBorderColor, text, numHotspot, w, h) {
    var imageData;
    imageData = computePastilles(w, h, bgColor, bgBorderColor, fgColor, fgBorderColor, text, numHotspot, w, h);
    return imageData;
  }

  useEffect(() => {
    if ( !iframeRef.current) return;

    // Set the session ID and start time
    setSessionId(Date.now().toString());
    setStartTime(Date.now());

    const client = new window.Sketchfab(iframeRef.current);

    const error = () => {
      console.error('Error initializing Sketchfab API');
    };

    const config = {
      success: function onSuccess(api) {
        setApiInstance(api);
        console.log("preview config Settings:", settings);
        api.start();
        api.addEventListener('viewerready', function () {

          console.log("viewer ready");

          // set AnnotationsTexture to invisible and small 1px pastilles
          var url = getNewPastilleURL('rgba(0,0,0,1)', 'rgba(0,0,0,1)', 'rgba(0,0,0,1)', 'rgba(0,0,0,1)', 0, 50, 512, 256, 1, 1);
          api.setAnnotationsTexture(url, function () {});

          // Fetch annotations and animations
          api.getAnnotationList(function(err, annotationsList) {
            if (!err) {
              const annotationInfos = annotationsList.map(annotation => ({
                raw: annotation.content.raw,
                name: annotation.name,
                eye: annotation.eye,
                index: annotation.index,
                target: annotation.target,
              }));
              setAnnotationsInfo(annotationInfos);
              runAnnotationLoop(api, annotationsList);
            }
          });

          api.getAnimations(function(err, animationsList) {
            if (!err) {
              setAnimations(animationsList);
            }
          });

          // Update startTime right before calculating the elapsed time
          setStartTime(prevTime => {
            const newTime = Date.now();
            const elapsedTime = newTime - prevTime;
            //console.log("elapsed time: ", elapsedTime);
            handleIframeLoad(elapsedTime);
            setIframeLoaded(true);

            return newTime;  // Return the new startTime
          });

          api.hideAnnotationTooltips(function(err) {
            if (!err) {
                window.console.log('Hiding annotation tooltip');
            }
          });

          api.getPostProcessing(function(settings) {
            window.console.log("preview getPostProcessing settings: ",settings);
          });

        });
        

        api.addEventListener('click',function(info) {
            window.console.log('click at', info.position2D);
            setClickCount(prevCount => prevCount + 1);
            setCoordinates(prevCoordinates => [...prevCoordinates, info.position2D]);
          },
          { pick: 'fast' }
        );

        api.addEventListener('nodeMouseEnter', function (node) {
          //console.log('node entered:', node.position2D);
          setClickCount(prevCount => prevCount + 1);
          setCoordinates(prevCoordinates => [...prevCoordinates, node.position2D]);
          },
          { pick: 'slow' }
        );

        // api.addEventListener('annotationSelect', function(index) {
        //   window.console.log('Selected annotation', index);
        //   if(index >= 0){
        //     const selectedAnnotation = annotationsInfo[index];
        //     setSelectedAnnotationTitle(selectedAnnotation?.name || '');
        //     setSelectedAnnotationBody(selectedAnnotation?.raw || '');
        //     setIsAnnotationHudVisible(true);
        //     setCurrentAnnotation(index);
        //   } else {
        //     setSelectedAnnotationTitle('');
        //     setSelectedAnnotationBody('');
        //     setIsAnnotationHudVisible(false);
        //     setCurrentAnnotation(index);
        //   }
        // });
          
      },
      autostart: settings.autostart,
      camera: settings.cameraintrozoom,
      orbit_constraint_pan: settings.orbitconstrictionpan,
      prevent_user_light_rotation: settings.preventlightrotation,
      scrollwheel: settings.scrollwheel,
      ui_controls: settings.ui_controls,
      annotations_visible: settings.uiannotations,
      ui_animations: settings.uianimations,
      ui_infos: settings.uiinfo,
      ui_stop: settings.uistop,
      ui_start: settings.uistart,
      ui_fullscreen: settings.uifullscreen,
      ui_annotations: settings.uiannotationsmenu,
      ui_hint: settings.uihint,
      ui_help: settings.uihelp,
      ui_sound: settings.uisound,
      ui_general_controls: settings.uigeneralcontrols,
      dof_circle: settings.dof,
      preload: settings.preload,
      merge_materials: settings.mergematerials,
      graph_optimizer: settings.graphoptimizer,
      material_packing: settings.materialpacking,
      ui_watermark: 0,
      ui_watermark_link: 0,
      ui_loading: settings.uiloading,
      autospin: settings.autospin ? settings.spinspeed : 0,
      ...(settings.ispancameralimitactive && { orbit_constraint_pitch_down: settings.pancameradownlimit }),
      ...(settings.ispancameralimitactive && { orbit_constraint_pitch_up: settings.pancamerauplimit }),
      ...(settings.iscamerayawlimitactive && { orbit_constraint_yaw_left: settings.yawcameraleftlimit }),
      ...(settings.ispancameralimitactive && { orbit_constraint_yaw_right: settings.yawcamerarightlimit }),
      ...(settings.iscamerazoomlimitactive && { orbit_constraint_zoom_in: settings.camerazoomcloselimit }),
      ...(settings.iscamerazoomlimitactive && { orbit_constraint_zoom_out: settings.camerazoomfarlimit }),
      transparent: settings.isoriginalbackground ? 0 : 1,
      //ui_vr: settings.uivr,
      //ui_ar: settings.uiar,
      //ui_ar_qrcode: settings.uiarqrcode,
      ui_vr: 0,
      ui_ar: 0,
      ui_ar_qrcode: 0,
      error: error
    };

    client.init(UID, config);
  }, [UID, settings]);

  const gotoAnnotation = (index) => {
    const annotation = annotationsInfo[index];
    if (apiInstance) {
      apiInstance.setCameraLookAt(
        annotation.eye,
        annotation.target,
        0.5,  // duration, 0 for instant
        () => {
          setCurrentAnnotation(index);
          // Show custom HUD
          setSelectedAnnotationNumber(index+1);
          setSelectedAnnotationTitle(annotation?.name || '');
          setSelectedAnnotationBody(annotation?.raw || '');
          setIsAnnotationHudVisible(true);
        }
      );
    }
  };
  

  const handleNextAnnotation = () => {
    const nextIndex = (currentAnnotation + 1) % annotations.length;
    gotoAnnotation(nextIndex);
  };

  const handlePreviousAnnotation = () => {
    const prevIndex = (currentAnnotation - 1 + annotations.length) % annotations.length;
    gotoAnnotation(prevIndex);
  };

  const playAnimation = () => {
    apiInstance.play(function(err) {
      if (!err) {
        setIsAnimationPlaying(true);
      }
    });
  };

  const pauseAnimation = () => {
    apiInstance.pause(function(err) {
      if (!err) {
        setIsAnimationPlaying(false);
      }
    });
  };

  const togglePlayPauseAnimation = () => {
    if (isAnimationPlaying) {
      pauseAnimation();
    } else {
      playAnimation();
    }
  };

  const setAnimationByIndex = (index) => {
    const animationUID = animations[index][0];
    apiInstance.setCurrentAnimationByUID(animationUID, function(err) {
      if (!err) {
        setCurrentAnimation(index);
      }
    });
  };

  const handleNextAnimation = () => {
    if (currentVisibleAnimation < animations.length - 1) {
      setCurrentVisibleAnimation(currentVisibleAnimation + 1);
    }
  };

  const handlePreviousAnimation = () => {
    if (currentVisibleAnimation > 0) {
      setCurrentVisibleAnimation(currentVisibleAnimation - 1);
    }
  };

  const handleSelectAnimation = (index) => {
    setIsAnimationPlaying(true)
    setCurrentVisibleAnimation(index); // Set the selected animation as the center
    setAnimationByIndex(index); // Switch to the selected animation
  };

  const handleToggleFullscreen = () => {
    if (iframeRef.current.requestFullscreen) {
      iframeRef.current.requestFullscreen();
    } else if (iframeRef.current.mozRequestFullScreen) { /* Firefox */
      iframeRef.current.mozRequestFullScreen();
    } else if (iframeRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      iframeRef.current.webkitRequestFullscreen();
    } else if (iframeRef.current.msRequestFullscreen) { /* IE/Edge */
      iframeRef.current.msRequestFullscreen();
    }
  };

// Add these functions within the component to handle hover events
const handleMouseEnter = (text, icon) => {
  setHoverText(text);
  setHoverIcon(icon);
};

const handleMouseLeave = () => {
  setHoverText('');
  setHoverIcon(null);
};

const handleOpenPopup = (type) => {
  setPopupType(type);
};

const handleClosePopup = () => {
  setPopupType(null);
};

const handleToggleHud = () => {
  setIsHudVisible(!isHudVisible);
  setExpandedSection(null);
};

const handleExpandSection = (section, tooltip) => {
  if (expandedSection === section) {
    setExpandedSection(null);
    setTooltipText('');
  } else {
    setExpandedSection(section);
    setTooltipText(tooltip);

    // If the animations section is expanded, close the annotation box and play the first animation
    if (section === 'animations') {
      // Close annotation HUD
      closeAnnotationHud();

      // Play the first animation if it's not already playing
      if (animations.length > 0) {
        setAnimationByIndex(0); // Automatically select the first animation
        playAnimation(); // Automatically start the animation
      }
    }

    // Automatically select the first annotation if the section is "annotations"
    if (section === 'annotations' && annotations.length > 0) {
      gotoAnnotation(0);  // Select the first annotation (index 0)
    }
  }
};

const onAnnotationPlaceholderClick = (annotation) => {
  if (apiInstance) {
    setIsCameraMoving(true);
    apiInstance.setCameraLookAt(
      annotation.eye,
      annotation.target,
      0.5,  // duration, 0 for instant
      () => {
        setIsCameraMoving(false);
      }
    );
    // Show custom HUD
    setSelectedAnnotationNumber(annotation.index + 1);
    setSelectedAnnotationTitle(annotation?.name || '');
    setSelectedAnnotationBody(annotation?.raw || '');
    setIsAnnotationHudVisible(true);
    setCurrentAnnotation(annotation.index);
  }
};

  const runAnnotationLoop = (apiInstance) => {
    console.info("annotationInfo: ", annotationsInfo)
    const updateAnnotations = () => {
      // console.info("updateAnnotations loop called");
      apiInstance.getAnnotationList((err, annotationsList) => {
        if (!err) {
          const updatedAnnotations = [];
          let completed = 0;
  
          annotationsList.forEach((annotation, index) => {
            const position = annotation.lastComputedScenePosition || annotation.lastComputedWorldPosition;
            apiInstance.getWorldToScreenCoordinates(position, (coord) => {
              const updatedAnnotation = { 
                ...annotation, 
                screenPosition: coord.canvasCoord 
              };
              updatedAnnotations[index] = updatedAnnotation;
  
              completed += 1;
  
              if (completed === annotationsList.length) {
                setAnnotations(updatedAnnotations);
              }
            });
          });

          // If there are no animations, always show annotations
          if (animations.length === 0) {
            setAnnotations((prevAnnotations) => 
              prevAnnotations.map((annotation) => ({
                ...annotation,
                visible: true // Make sure they are always visible
              }))
            );
          }
        }
      });
  
      requestAnimationFrame(updateAnnotations);
    };

    // apiInstance.getAnnotationList((err, annotationsList) => {
    //   annotationsList.forEach((annotation, index) => {
    //     apiInstance.hideAnnotation(index, function(err, tempindex){
    //       if(!err) window.console.log("hiding annotation ", tempindex)
    //     });
    //   });
    // });

    updateAnnotations();
  };
  
  function viewLeft(){
    console.log("setting viewLeft at :", settings?.hudpositiondistance);
    apiInstance.getCameraLookAt(function(err, camera) {
        if (!err) {
            const center = camera.target; // Get the current target (center of the model)
            const distance = settings.hudpositiondistance;
  
            // Move camera to the left of the center
            const newPosition = [center[0] - distance, center[1], center[2]];
  
            // Set the new camera position, targeting the center
            apiInstance.setCameraLookAt(newPosition, center, 1.0, function(err) {
                if (!err) {
                    console.log('Left view set.');
                }
            });
        }
    });
  }
  
  function viewRight(){
    apiInstance.getCameraLookAt(function(err, camera) {
      if (!err) {
          const center = camera.target;
          const distance = settings.hudpositiondistance;

          // Move camera to the right of the center
          const newPosition = [center[0] + distance, center[1], center[2]];

          apiInstance.setCameraLookAt(newPosition, center, 1.0, function(err) {
              if (!err) {
                  console.log('Right view set.');
              }
          });
      }
  });
}
  
  function viewTop(){
    apiInstance.getCameraLookAt(function(err, camera) {
      if (!err) {
          const center = camera.target;
          const distance = settings.hudpositiondistance;

          // Move camera above the center
          const newPosition = [center[0], center[1] + distance, center[2]];

          apiInstance.setCameraLookAt(newPosition, center, 1.0, function(err) {
              if (!err) {
                  console.log('Top view set.');
              }
          });
      }
    });
  }
  
  function viewFront(){
    apiInstance.getCameraLookAt(function(err, camera) {
      if (!err) {
          const center = camera.target;
          const distance = settings.hudpositiondistance;

          // Move camera in front of the center (z-axis)
          const newPosition = [center[0], center[1], center[2] + distance];

          apiInstance.setCameraLookAt(newPosition, center, 1.0, function(err) {
              if (!err) {
                  console.log('Front view set.');
              }
          });
      }
    });
  }

  function viewReset(){
    apiInstance.recenterCamera(function(err) {
      if (!err) {
          window.console.log('Camera recentered');
      }
    });
  }

  const closeAnnotationHud = () => {
    setIsAnnotationHudVisible(false);
    setCurrentAnnotation(-1);
  };

  return (
    <>
    {/* Emulare il funzionamento del popup di Plugin.js che è nell' OUTPUT */}
    {popupType && (
    <ArVrPopupContainer>
      <ArVrPopupContent>
        <HeaderPopup>
          <TitlePopup>{popupType} - QRCODE</TitlePopup>
          <CloseButtonPopup onClick={handleClosePopup}>X</CloseButtonPopup>
        </HeaderPopup>
        <BodyPopup>
            {popupType === 'AR' ? (
              <>
                <p>Inquadra il code QR per vedere il modello in Realtà Aumentata ( AR ) o se stai utilizzando un cellulare allora clicca il pulsante qui sotto per accedere direttamente alla visualizzazione AR.</p>
                <QRCodePopup>
                  <QRCode size="256" value={settings.arqrlink} />
                </QRCodePopup>
              </>
              ) : (
              <>
                <p>Inquadra il code QR per vedere il modello in Realtà Virtuale ( VR ) o se stai utilizzando un visore allora clicca il pulsante qui sotto per accedere direttamente alla visualizzazione VR.</p>
                <QRCodePopup>
                  <QRCode size="256" value={settings.vrqrlink} />
                </QRCodePopup>
              </>
            )}
        </BodyPopup>
        <FooterPopup>
          <LinkButtonPopup onClick={() => window.open(popupType === 'AR' ? settings.arqrlink : settings.vrqrlink, '_blank')}>
            Visualizza in {popupType}
          </LinkButtonPopup>   
        </FooterPopup>
      </ArVrPopupContent>
    </ArVrPopupContainer>
    )}
    {settings?.showhudpositions && (
    <ButtonPositionsContainer>
      <ButtonPosition onClick={viewFront}>
        <img src={posizione4} alt="Front" width={"24px"} height={"24px"} />
      </ButtonPosition>
      <ButtonPosition onClick={viewLeft}>
        <img src={posizione1} alt="Left" width={"24px"} height={"24px"} />
      </ButtonPosition>
      <ButtonPosition onClick={viewRight}>
        <img src={posizione3} alt="Right" width={"24px"} height={"24px"} />
      </ButtonPosition>
      <ButtonPosition onClick={viewTop}>
      <img src={posizione2} alt="Top" width={"24px"} height={"24px" }/>
      </ButtonPosition>
      <ButtonResetPosition onClick={viewReset}>
        Reset Camera
      </ButtonResetPosition>
    </ButtonPositionsContainer>
    )}

    {isHudVisible && (
    <ButtonBorder>
        {hoverIcon && (
        <ButtonHover id="button-hover">
          <IconHover src={hoverIcon} />
          {hoverText}
        </ButtonHover>
        )}
        <ButtonContainer isVisible={isHudVisible}>
        
        { animations.length >= 2 && (<>
          <HudBigButton onClick={togglePlayPauseAnimation}
            onMouseEnter={() => handleMouseEnter(isAnimationPlaying ? 'Pause' : 'Play', isAnimationPlaying ? pauseicon : playicon)}
            onMouseLeave={handleMouseLeave}>
            <img src={isAnimationPlaying ? pauseicon : playicon} alt='play/pause' width={"20px"} />
          </HudBigButton>
          </>)}
          
          {expandedSection !== 'qr' && (
          <HudBigButton onClick={() => handleExpandSection('qr', 'QR Codes')}
            onMouseEnter={() => handleMouseEnter('QR Codes', qr)}
            onMouseLeave={handleMouseLeave}>
            <img src={qr} alt='QR Codes' width={"20px"} />
          </HudBigButton>
          )}
          {expandedSection === 'qr' && (
          <ExpandedSection isExpanded={expandedSection === 'qr'}>
          <HudSpacer/>
          <HudBigButton onClick={() => handleOpenPopup('AR')}
            onMouseEnter={() => handleMouseEnter('AR', ar)}
            onMouseLeave={handleMouseLeave}>
            <img src={ar} alt='AR' width={"20px"} />
          </HudBigButton>
          <HudBigButton onClick={() => handleOpenPopup('VR')}
            onMouseEnter={() => handleMouseEnter('VR', vr)}
            onMouseLeave={handleMouseLeave}>
            <img src={vr} alt='VR' width={"20px"} />
          </HudBigButton>
          <HudSpacer/>
          <SectionTooltip>
            <IconTooltip src={annotation}/>
            QR Codes
          </SectionTooltip>
          </ExpandedSection>
          )}

          { annotations.length >= 1 && (<>
          {expandedSection !== 'annotations' && (
          <HudBigButton onClick={() => handleExpandSection('annotations', 'Annotations')}
            onMouseEnter={() => handleMouseEnter('Annotations', annotation)}
            onMouseLeave={handleMouseLeave}>
            <img src={annotation} alt='Annotations' width={"32px"} />
          </HudBigButton>
          )}
          {expandedSection === 'annotations' && (
            <ExpandedSection isExpanded={expandedSection === 'annotations'}>
          <HudSpacer />
              <HudSmallButton onClick={handlePreviousAnnotation}
                onMouseEnter={() => handleMouseEnter('Previous', prev)}
                onMouseLeave={handleMouseLeave}>
                <img src={prevhud} alt='Previous' width={"32px"} />
              </HudSmallButton>
              {/* {annotations.map((annotation, index) => (
                <HudBigButton key={index} onClick={() => gotoAnnotation(index)}>
                  {index + 1}
                </HudBigButton>
              ))} */}
              <HudSmallButton onClick={handleNextAnnotation}
                onMouseEnter={() => handleMouseEnter('Next', next)}
                onMouseLeave={handleMouseLeave}>
                <img src={nexthud} alt='Next' width={"32px"} />
              </HudSmallButton>
              <SectionTooltip>
                <IconTooltip src={annotation}/>
                Annotations
              </SectionTooltip>
              <HudSpacer />
            </ExpandedSection>
          )}
          </>)}

          { animations.length >= 2 && (<>
          {expandedSection !== 'animations' && (
          <HudBigButton onClick={() => handleExpandSection('animations', 'Animations')}
            onMouseEnter={() => handleMouseEnter('Animations', animation)}
            onMouseLeave={handleMouseLeave}>
            <img src={animation} alt='Animations' width={"32px"} />
          </HudBigButton>
          )}
          {expandedSection === 'animations' && (
            <ExpandedSection isExpanded={expandedSection === 'animations'}>
              <HudSpacer />
              <HudSmallButton onClick={handlePreviousAnimation}
                onMouseEnter={() => handleMouseEnter('Previous', prev)}
                onMouseLeave={handleMouseLeave}
                disabled={currentVisibleAnimation === 0}>
                <img src={prevhud} alt='Previous' width={"32px"} />
              </HudSmallButton>
              {/* Render the animation buttons conditionally */}
              {animations.length > 1 && (
                    <>
                      {/* If currentVisibleAnimation is 0, render the first three buttons */}
                      {currentVisibleAnimation === 0 && animations.length >= 3 && (
                        <>
                          <HudBigButton onClick={() => handleSelectAnimation(0)}>
                            1 {/* First animation */}
                          </HudBigButton>
                          <HudBigButton onClick={() => handleSelectAnimation(1)}>
                            2 {/* Second animation */}
                          </HudBigButton>
                          <HudBigButton onClick={() => handleSelectAnimation(2)}>
                            3 {/* Third animation */}
                          </HudBigButton>
                        </>
                      )}

                      {/* If currentVisibleAnimation is greater than 0, use normal shifting logic */}
                      {currentVisibleAnimation > 0 && (
                        <>
                          {/* First button (left of center) */}
                          {currentVisibleAnimation > 0 && (
                            <HudBigButton onClick={() => handleSelectAnimation(currentVisibleAnimation - 1)}>
                              {currentVisibleAnimation} {/* Display previous animation */}
                            </HudBigButton>
                          )}

                          {/* Center button (current animation) */}
                          <HudBigButton onClick={() => handleSelectAnimation(currentVisibleAnimation)}>
                            {currentVisibleAnimation + 1} {/* Display current animation */}
                          </HudBigButton>

                          {/* Third button (right of center) */}
                          {currentVisibleAnimation < animations.length - 1 && (
                            <HudBigButton onClick={() => handleSelectAnimation(currentVisibleAnimation + 1)}>
                              {currentVisibleAnimation + 2} {/* Display next animation */}
                            </HudBigButton>
                          )}
                        </>
                      )}
                    </>
                  )}
              <HudSmallButton onClick={handleNextAnimation}
                onMouseEnter={() => handleMouseEnter('Next', next)}
                onMouseLeave={handleMouseLeave}
                disabled={currentVisibleAnimation >= animations.length - 1} >
                <img src={nexthud} alt='Next' width={"32px"} />
              </HudSmallButton>
              <SectionTooltip>
                <IconTooltip src={animation}/>
                Animations
              </SectionTooltip>
              <HudSpacer />
            </ExpandedSection>
          )}
          </>)}
        </ButtonContainer>
      </ButtonBorder>
    )}
    <AnnotationsContainer>
  {annotations.map((annotation, index) => (
    <AnnotationContainer 
      onClick={isCameraMoving ? undefined : () => {
        onAnnotationPlaceholderClick(annotation.eye, annotation.target); 
        gotoAnnotation(index);
      }}
      key={index}
      style={{
        position: "absolute",
        transform: `translate(${(annotation.screenPosition && annotation.screenPosition[0]-12) || 0}px, 
                  ${(annotation.screenPosition && (annotation.screenPosition[1]-12)) || 0}px)`,
        pointerEvents: isCameraMoving ? 'none' : 'auto',
        display: 
          // Logic for showing the dots
          // Case 1: Show only the selected annotation dot when the annotation box is open
          isAnnotationHudVisible && currentAnnotation === index 
            ? 'flex' 
            // Case 2: Show all annotation dots when the annotation box is closed and no animation is playing or no animations exist
            : (!isAnnotationHudVisible && (!isAnimationPlaying || animations.length === 0)) ? 'flex' : 'none',
          backgroundColor: settings.annotationdotcolor, // Set background color dynamically
          color: settings.annotationtextcolor,         // Set text color dynamically
      }}
    >
      {index + 1}
    </AnnotationContainer>
  ))}
    {isAnnotationHudVisible && currentAnnotation >= 0 ? (
      <AnnotationHudContainer>
        <AnnotationHudFrame>
          <AnnotationHudExternalHead>
            <AnnotationHudNumber>{selectedAnnotationNumber}</AnnotationHudNumber>
            <AnnotationHudHeadClose onClick={closeAnnotationHud} src={close}/>
          </AnnotationHudExternalHead>
          <AnnotationHudWrapper>
            <AnnotationHudHead>
              <AnnotationHudTitle>{selectedAnnotationTitle}</AnnotationHudTitle>
            </AnnotationHudHead>
            <AnnotationHudBody>
              {selectedAnnotationBody}
            </AnnotationHudBody>
            <AnnotationHudFooter>
              <AnnotationHudFooterButtonContainer>
                <AnnotationHudFooterPreviousButton onClick={handlePreviousAnnotation}>
                  <img src={prev} alt='Previous' width={"20px"} />
                </AnnotationHudFooterPreviousButton>
                <AnnotationHudFooterButton onClick={closeAnnotationHud}>Close</AnnotationHudFooterButton>
                <AnnotationHudFooterNextButton onClick={handleNextAnnotation}>
                  <img src={next} alt='Previous' width={"20px"} />
                </AnnotationHudFooterNextButton>
              </AnnotationHudFooterButtonContainer>
            </AnnotationHudFooter>
          </AnnotationHudWrapper>
        </AnnotationHudFrame>
      </AnnotationHudContainer>
    ) : (<></>)}
    <IFrame
        ref={iframeRef}
        title="Sketchfab Viewer"
        src=""
        allowFullScreen
        allow='xr-spacial-tracking'
        id="SFpreview"       
        width={settings.iswidthconstraintactive ? settings.widthconstraint + 'px' : "100%"}
        height={settings.isheightconstraintactive ? settings.heightconstraint + 'px' : "100%"}
        frameBorder={"0"}
        style={{ 
          position: 'absolute',
          borderRadius: '24px',
          backgroundColor: settings.isoriginalbackground ? 'transparent' : 
                           (settings.backgroundtransparent ? 'transparent' : 
                           (/^#[0-9A-F]{6}$/i.test(settings.backgroundcolor) ? settings.backgroundcolor : 'black'))
        }}
        ></IFrame>
    </AnnotationsContainer>
    </>
  );
};

export default ConfigurationPreview;