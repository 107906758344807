import React, { useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../../hooks/useAuth.js';
import { useWebSocket } from '../../context/WebSocketContext';

import {ButtonUpload,DragAndDrop,ErrorContent,ErrorWrapper,UploadWindowContainer,UploadWindowWrapper,
  WindowContainer,WindowFooter,WindowHeader,WindowDescription,UploadAreaWrapper, DragAndDropFirstRow,
  DragAndDropSecondRow, InputFileLabel, UploadTitleWrapper, UploadImg,NotificationBarIcon,NotificationBarText,
  InputFile, UploadButton,NotificationBarContainer,
  InputTitle,
  UploadButtonsContainer,
  UploadHomeButton,
  UploadModelButton} from './uploadformElements.js';
import uploadicon from '../../img/upload72icon.png';
import tickicon from '../../img/tick24icon.png';

const UploadForm = (props) => {
  const [error, setError] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [textareaText, setTextareaText] = useState(null);
  const [dbaselectedUser,setDbaselectedUser] = useState(null);
  const [title, setTitle] = useState('');
  const [titleConfirmed, setTitleConfirmed] = useState(false);
  const [fileConfirmed, setFileConfirmed] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [useridtemp, setUseridtemp] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(0);
  const [parsedMessage, setParsedMessage] = useState(null);
  const [uploadedModel, setUploadedModel] = useState(null);
  const [modelUID, setModelUID] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [currentUploadUID, setCurrentUploadUID] = useState(null);
  const inputRef = useRef(null);

  let retries = 10;

  const { auth } = useAuth();   
  const { messages, modelNameMap, setModelNameMap } = useWebSocket();
  const navigate = useNavigate();
  const id = auth?.id;
  const secretapi = `${process.env.REACT_APP_API_BASE_URL}`;

  useEffect(() => {
    // Listen for UPLOAD_OUTCOME messages and update the status
    const handleWebSocketMessages = (message) => {
      if (message.type === 'UPLOAD_OUTCOME' && isUploading) {
        const { status, model } = message.data;
        
        // Check if the message is related to the current upload
        if ((status === "PROCESSING" || status === "INITIALIZED" || status === "SUCCEEDED") && !currentUploadUID) {
          // Set the current upload UID if it's the first message (PROCESSING)
          setCurrentUploadUID(model.uid);
          console.log("setting uploadUID to " + model.uid + " for model: " + title);    
          // Set the model name in the map
          setModelNameMap(prevMap => ({
            ...prevMap,
            [model.uid]: title
          }));
          setInfoWindow("Puoi chiudere questa finestra, lo stato dell'upload ti verrà notificato tramite Toast Notification.")
        }

        if (model.uid === currentUploadUID) {
          console.log(`UPLOAD_OUTCOME status: ${status}`);
          if (status === "INITIALIZED") {
            setUploadStatus(1);
            setTextareaText('Model uploading. INITIALIZED...');
          }
            else if (status === "PROCESSING") {
            setUploadStatus(2);
            setTextareaText('Model uploading. PROCESSING...');
          } else if (status === "SUCCEEDED") {
            setUploadStatus(3);
            setTextareaText('Model uploading. SUCCEEDED...');
          } else if (status === "FINALIZING") {
            setUploadStatus(4);
            setTextareaText('Model uploading. FINALIZING...');
          } else if (status === "FINALIZED") {
            setUploadedModel(model);
            setUploadStatus(5);
            setIsUploading(false);
            setCurrentUploadUID(null); // Reset the UID after finalization
            setTextareaText('Model correctly uploaded, processed, and finalized.');
            setInfoWindow("")
          } else if (status === "FAILED") {
            setError('Model upload failed');
            setIsUploading(false);
            setCurrentUploadUID(null); // Reset the UID on failure
            setInfoWindow("")
          } else {
            setTextareaText(`Current status: ${status}`);
            setIsUploading(false);
            setCurrentUploadUID(null); // Reset the UID on unknown status
            setInfoWindow("")
          }
        }
      }
    };

    messages.forEach(handleWebSocketMessages);

  }, [messages, isUploading, currentUploadUID]);

  useEffect(() => {
    if (selectedFile && inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedFile]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleUploadClick();
    }
  };

    const handleDrop = async (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      const reader = new FileReader();
    
      const allowedExtensions = ["obj","blend","fbx","gltf","glb","3dc","asc","3ds","abc","dae","zae","igs","iges","las","ply","stl","usd","usdz","usda","usdc","zip","rar","7z"];
    
      const fileExtension = file.name?.split('.').pop();
      console.log("estensione: "+ fileExtension);
      if (!(allowedExtensions.includes(fileExtension.toLowerCase()))) {
        // If file extension is not allowed, show error message and return
        console.log('File type not allowed');
        return;
      }

      console.log("handleDrop");
      console.log(file);
      setSelectedFile(file)
    
      if (!fileConfirmed) {
        setFileConfirmed(true);
        return;
      }
      
      console.log("handleDrop");
      console.log("file handleDrop: " + file);
      setSelectedFile(file);
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        setError(null);
        if (e.key === 'Enter') {
          setTitleConfirmed(true);
          setTextareaText("Stiamo caricando il tuo modello. L'Upload può impiegare diversi minuti.");
        }
      };

    const handleConfirmTitle = () => {
      if (title.trim() !== '') {
        setTitleConfirmed(true);
        setTextareaText("Stiamo caricando il tuo modello. L'Upload può impiegare diversi minuti.");
        uploadFile(selectedFile);
      } else {
        setError('Please enter a valid title');
      }
    };

    const handleFileSelect = (e) => {
      const file = e.target.files[0];
      console.log(file)
      const allowedExtensions = ["obj","blend","fbx","gltf","glb","3dc","asc","3ds","abc","dae","zae","igs","iges","las","ply","stl","usd","usdz","usda","usdc","zip","rar","7z"];
    
    
      const fileExtension = file.name.split('.').pop();
      if (!(allowedExtensions.includes(fileExtension.toLowerCase()))) {
        // If file extension is not allowed, show error message and return
        console.log('File type not allowed');
        return;
      }
    
      console.log("handleFileSelect");
      console.log(file);
      setSelectedFile(file);

      if (!fileConfirmed) {
        setFileConfirmed(true);
        console.log("fileconfirmed")
      }
    };

    const handleUploadClick = () => {
        if (selectedFile) {
          //handleDrop({ dataTransfer: { files: selectedFiles } });
          uploadFile(selectedFile)
        }
    };

    const uploadFile = async (file) => {
      console.log("uploadFile");
      console.log("file:" + file);
      try {
        setUploading(true);
        //setIsUploading(true);
        setTextareaText('Uploading, Please Wait');
    
        const formData = new FormData();
    
        formData.append("userid", props.selectedUser);
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("colluid", props.selectedCollection ? props.selectedCollection : auth.collectionuid);
        formData.append("name", title);
        formData.append("description", "1");
        formData.append("tags", "1");
        formData.append("categories", "people");
        formData.append("license", "by");
        formData.append("isArEnabled", true);
        formData.append("isPublished", true);
        formData.append("isInspectable", true);

        console.log("Axios Upload");
        console.log('Constructed URL:', `${secretapi}/portus/upload`);

        const response = await axios.post(`${secretapi}/portus/upload`, 
        //const response = await axios.post(secretapi + '/portus/upload-async-forum', 
          formData, {
          headers: {
            'Authorization': `Bearer ${auth?.accessToken}`,
            'id': auth.id,
            'Content-Type': 'multipart/form-data'
          },
          timeout: 1200000 // Set the timeout to 20 minute (1,200,000 milliseconds)
        });
    
        if (response.data.success) {
          const uploadId = response.data.message;
          // Call the API to check the upload status
          console.log("Axios Upload status code:");
          console.log(response.data.message);
          setUploadStatus(1);
          setIsUploading(true);


          // checkUploadStatus(uploadId);
          
        } else {
          console.log('Error uploading model');
          setTextareaText('Error uploading model');
          setTitleConfirmed(false);
          setUploading(false);
          setIsUploading(false);
          console.log(response);
        }
      } catch (error) {
        setTextareaText('Error: ' + error);
        console.error(error);
        setUploading(false)
        setIsUploading(false);
      }
    };

  const handleUploadError = (error) => {
    console.error('Errore:', error);
    setError(`Errore: ${error.message}`);
    setTextareaText(`Errore: ${error}`);
    setTitleConfirmed(false);
    setUploading(false);
    setIsUploading(false);
  };
  
  function handleGoToUplaodedModel(){
    console.log("handlegotomodel clicked");
    navigate('/configurations', {
      state: {
        conftest: uploadedModel.confTest,
        confprod: uploadedModel.confProd,
        type: 'prod',
        modelInfo: uploadedModel,
        configurationAlreadyOpen: false
      }
    });
  }

  const handleAssignModel = async () => {
    
    const formData = new FormData();
    
    formData.append("userid", props.selectedUser ? props.selectedUser : auth.id);
    formData.append("fileName", "test assign dba");
    formData.append("colluid", props.selectedCollection ? props.selectedCollection : auth.collectionuid);
    formData.append("name", "test assign dba");
    formData.append("description", "1");
    formData.append("tags", "1");
    formData.append("categories", "people");
    formData.append("license", "by");
    formData.append("isArEnabled", true);
    formData.append("isPublished", true);
    formData.append("isInspectable", true);
    formData.append("uid", modelUID);
    try {
        const response = await axios.post(secretapi + '/portus/assign', {
            uid: modelUID,
            userid: props.selectedUser ? props.selectedUser : auth.id,
            fileName: "test assign dba",
            colluid: props.selectedCollection ? props.selectedCollection : auth.collectionuid,
            name : "test assign dba",
            description: "1",
            tags: ["1"],
            categories : ["people"],
            license: "by",
            isArEnabled: true,
            isPublished: true,
            isInspectable: true
        }, {
          headers: {
            'Authorization': `Bearer ${auth?.accessToken}`,
            'id': auth.id
          },
        });

        // Gestisci la risposta come necessario
        if (response.data.success) {
            console.log('Modello assegnato con successo!');
        } else {
            console.log('Errore nell\'assegnare il modello');
        }
    } catch (error) {
        console.error('Errore durante l\'assegnazione:', error);
    }
};

    return(
        <UploadWindowContainer>
            <UploadWindowWrapper>
                <WindowHeader>Carica modello</WindowHeader>
                <WindowDescription>
                  {!fileConfirmed ? (<>Seleziona il file 3d per caricarlo nella libreria ( Max 500MB ). Supportiamo FBX, OBJ, DAE, BLEND, STL, e <a href="https://help.sketchfab.com/hc/en-us/articles/202508396" target="_blank" >molti altri formati</a>. 
                    È anche possibile caricare un archivio come ZIP, RAR, o 7z, che contiene le tue texture, materiali e mesh.</>) :
                   !uploading && uploadStatus !== 5 && !error ? (<>Rinomina il modello per identificarlo nella libreria.</>) :
                    (<></>)}
                  {uploading && (<>{textareaText}</>)}
                  {uploadStatus === 5 && 
                  (<>
                    <NotificationBarContainer>
                      <NotificationBarIcon src={tickicon}></NotificationBarIcon>
                      <NotificationBarText>Modello caricato con successo!</NotificationBarText>
                    </NotificationBarContainer></>)}
                </WindowDescription>
                <WindowContainer>
                  {fileConfirmed ? (
                    <>
                      <UploadTitleWrapper>
                        <UploadImg src={uploadicon}></UploadImg>
                        <InputTitle type="text" value={title} onChange={handleTitleChange} 
                        placeholder={uploadStatus === 5 ?  title :  selectedFile?.name} disabled={uploading | uploadStatus === 5 | error}
                        onKeyPress={handleKeyPress}
                        ref={inputRef}/>
                        <br></br>
                        {uploadStatus === 5 ? (<>
                          <UploadButtonsContainer>
                            <UploadHomeButton onClick={() =>{ setUploadStatus(0); window.location.reload()}}>Vai alla Home</UploadHomeButton>
                            <UploadModelButton onClick={() => { setUploadStatus(0); handleGoToUplaodedModel()}}>Vai al Modello</UploadModelButton>
                            
                          </UploadButtonsContainer>
                        </>) : (
                        <UploadButton onClick={handleConfirmTitle} uploadstatus={uploadStatus} forceupdate disabled={uploading}>
                          {uploadStatus === 1 ? ("CARICAMENTO...  1/5") 
                          : uploadStatus === 2 ? ("CARICAMENTO...  2/5")
                          : uploadStatus === 3 ? ("CARICAMENTO...  3/5")
                          : uploadStatus === 4 ? ("CARICAMENTO...  4/5")
                          : uploadStatus === 0 & uploading ? ("CARICAMENTO...  0/5")
                          : error !== null ? ("ERRORE")
                          :                     ("PROSEGUI") 
                          }
                        </UploadButton>
                        )}
                      </UploadTitleWrapper>
                    </>
                  ) : !fileConfirmed ? (
                    <>
                      <UploadAreaWrapper>
                        {error && <p>Error: {error}</p>}
                        <DragAndDrop onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} disabled={uploading}>
                          <DragAndDropFirstRow>Trascina il modello</DragAndDropFirstRow> 
                          <DragAndDropSecondRow>oppure</DragAndDropSecondRow> 
                          <InputFile type="file" name="fileinput" id="fileinput" onChange={handleFileSelect} disabled={uploading} />
                          <InputFileLabel htmlFor="fileinput">Carica File</InputFileLabel>
                          {error}
                        </DragAndDrop>
                      </UploadAreaWrapper>
                    { props.selectedUser && auth.roles === 3 && (
                    <>
                      <br/>
                      Oppure inserisci l'uid del modello sketchfab. 
                      <input type="text" value={modelUID} onChange={(e) => setModelUID(e.target.value)} />
                      <button onClick={() => handleAssignModel()}>Assegna Modello</button>
                    </>
                    )}
                    </>
                  ) : uploadStatus === 5 ? (
                    <>

                    </>
                  ) : (
                    <>
                      Errore
                    </>
                  )}
                  {error &&
                  <ErrorWrapper>
                    <ErrorContent>{error}</ErrorContent>
                  </ErrorWrapper>
                  }
                  <WindowFooter>
                    {infoWindow}
                  </WindowFooter>
                </WindowContainer>
            </UploadWindowWrapper>
        </UploadWindowContainer>
    )
}

export default UploadForm;